import React, { useEffect } from 'react';
import { motion, usePresence } from "framer-motion"
import sanityProvider from '../../libs/sanity';
import ReponsiveSanityImage from '../common/ResponsiveImage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BlockContent from '@sanity/block-content-to-react'
import ResponsiveSanityImage from '../common/ResponsiveImage';
import classNames from 'classnames/bind';
import { TwitterShareButton, LinkedinShareButton } from "react-share";
import AnimatedImage from '../common/AnimatedImage';
import { formatDate } from '../../libs/date'

function UpdateBlock(props) {
  const { block } = props;

  const text =
    <div className='row page-update__text '>
      <BlockContent serializers={sanityProvider.blockContentSerializers} renderContainerOnSingleChild={true} className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3' blocks={block.copy}/>
    </div>

  let image = null;

  if(block.image) {
    const imageClasses = classNames('row', 'page-update__image', `page-update__image--${block.imagePosition}`)
    const innerClasses = classNames('col-m-4', {
      'col-t-4 off-t-2 col-d-6 off-d-3': block.imagePosition !== 'left'
    })
    image = <div className={imageClasses}>
      <div className={innerClasses}>
        <AnimatedImage>
          <ResponsiveSanityImage asset={block.image.asset} maxWidth={1440}/>
          {block.imageCaption &&
            <span>{block.imageCaption}</span>
          }
        </AnimatedImage>
      </div>
    </div>
  }
  return(
    <>{text}{image}</>
  );
}

function Update(props) {
  const [isPresent, safeToRemove] = usePresence();

  const update = props.update;

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  const currentUrl = (typeof window !== 'undefined') && window.location.href;

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key={`update${update.slug.current}`}>
      <section className='page-update grid'>
        <div className='row'>
          <div className='page-update__hero col-m-4 col-t-8 col-d-12'>
            <ReponsiveSanityImage asset={update.picture?.asset} maxWidth={1440}/>
          </div>
          <h1 className='page-update__title col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>{update.title}</h1>
          <div className='page-update__meta col-m-4 col-t-4 off-t-2 col-d-3'>
            <span>{formatDate(update.publishedAt, true)}</span>
            <span>
              <TwitterShareButton round="false" url={currentUrl}><FontAwesomeIcon icon={['fab', 'twitter']} /></TwitterShareButton>
              <LinkedinShareButton round="false" url={currentUrl}><FontAwesomeIcon icon={['fab', 'linkedin']} /></LinkedinShareButton>
            </span>
          </div>
        </div>
        { update.blocks?.map( (block,i) => {
          return(
            <UpdateBlock block={block} key={i}/>
          );
        })}
      </section>
    </motion.div>
  )
}

export default Update;