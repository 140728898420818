import ResponsiveAnimations from './responsiveAnimations'
import { TimelineMax } from "gsap";

class MobileAnimations extends ResponsiveAnimations {

  /* This is based on the intro1 parameter to animate all the way to r7 logo at full opacity */
  scrollAmountOnLanding = window.innerHeight * 3 * 2.5 / 5.5;

  createAnimations() {
    return this.createPageTimeline([
      this.intro1(),
      this.intro2(),
      this.intro3(),
      this.panel_pia(),
      this.panel_moon(),
      this.panel_dunes(),
      this.panel_sand(),
      this.panel_risky(),
      this.panel_change(),
      this.panel_lookfor(),
      this.panel_scotty(),
      this.panel_bond(),
      this.panel_eclipse(),
      this.panel_verb(),
      this.panel_hindsight(),
      this.panel_whatwedo(),
      this.end_screen()
    ])
  }

  dispose() {

  }

  intro1() {

    this.tagForClear([  "#intro .bg-starfield", "#intro .logo", "#intro .iss" ])

    return new TimelineMax( this.timelineParams('#intro .part-spacelogo', {
      scrollTrigger: {
        scrub: true,
        end: "bottom bottom"
      }
    }))
      /* opacity 0.01 prevents stutter on first display */
      .fromTo(  "#intro .bg-starfield",  { opacity: 0.01, scale: 1.116 },  { duration: 2, opacity: 0.25, scale: 1 },             0 )
      .fromTo(  "#intro .logo",         { opacity: 0.01, scale: 0.33 },   { duration: 2.25, opacity: 1, scale: 1 },             0 )
      .fromTo(  "#intro .iss",          { opacity: 0.01 },                { duration: 1.75, opacity: 1 },                       0.7 )
      .fromTo(  "#intro .iss",          { x: '-30%', y: '75vh' },         { duration: 2.50, y: '55vh', ease: "power1.inOut" },  0 )

      .addLabel("intro1-out", 3)
      .to(      "#intro .iss",          { duration: 3, y:'-20%', ease: "power1.inOut" },     "intro1-out" )
      .to(      "#intro .iss",          { duration: 3, opacity:0, ease: "power1.inOut" },  "intro1-out" )
      .to(      "#intro .logo",         { duration: 0.8, opacity:0, ease: "power1.inOut" },   "intro1-out")
  }

  intro2() {

    this.tagForClear([  "#intro .moon", "#intro .bg-starfield",
                        "#intro .copy1", "#intro .copy2", "#intro .copy3" ])

    const copyElements = [
      "#intro .copy1",
      "#intro .copy2",
      "#intro .copy3"
    ];

    const copy_in = new TimelineMax( this.timelineParams("#intro .copy", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "bottom bottom"
      }
    }) )
      .addLabel("intro2")
      .fromTo( copyElements,                 { visibility: 'hidden' },  { visibility: 'visible', duration: 0.01 },                    "intro2" )
      .fromTo(  "#intro .copy1",     { opacity:0, scale: 0.7 }, { opacity:1, scale: 1, duration: 3, ease: "power1.inOut" },   "intro2+=0.7" )
      .fromTo(  [ "#intro .copy2",
                  "#intro .copy3" ], { opacity:0, scale: 0.7 }, { opacity:1, scale: 1, duration: 2.5, ease: "power1.inOut" }, "intro2" )

    const copy_out = new TimelineMax( this.timelineParams("#intro .moon_and_panels", {
      scrollTrigger: {
        start:  "top top+=75%",
        end:    "top top",
      }
    }) )
      .addLabel("intro2-fade")
      .to( "#intro .bg-starfield", { duration: 5, opacity: 0, ease: "power1.inOut" },  "intro2-fade" )
      .to( copyElements,            { opacity:0, duration: 2, y: '-20vh' },             "intro2-fade" )

    return [copy_in, copy_out]
  }

  intro3() {

    this.tagForClear([  "#intro", "#intro .part-copyout", "#starfieldZoom",
                        "#intro .copy_out1", "#intro .copy_out2" ])

    /* This is only responsible for the pinning */
    const pinning = new TimelineMax( this.timelineParams("#intro .part-copyout", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
      }
    }) )

    /* Fade text in as soon as element reaches 50% of the window */
    const text_in = new TimelineMax( this.timelineParams("#intro .part-copyout", {
      duration: 6, /* This allows the text to stick for a couple "seconds" */
      scrollTrigger: {
        scrub: true,
        start: "top bottom-=50%",
      }
    }) )
      .addLabel("intro3", "0")
      .fromTo ( "#intro .copy_out1", { opacity:0, y:'20vh'} , { opacity:1, y:0, duration:4 }, "intro3" )
      .fromTo ( "#intro .copy_out2", { opacity:0, y:'20vh'} , { opacity:1, y:0, duration:2 }, "intro3" )

    /* Fade out text when intro is starting to go away */
    const text_out =  new TimelineMax( this.timelineParams("#intro", {
      scrollTrigger: {
        start: "bottom top",
        end: "bottom top-=50%",
        scrub: true,
      }
    }) )
      .addLabel("intro3_fadeout")
      .to ( [ "#intro .copy_out1", "#intro .copy_out2" ], { opacity:0, duration:1.75 }, "intro3_fadeout" )


    /* Star Zoom */
    const starZoomFrameRef = {
      currentFrame: 0
    }

    const starZoom =  new TimelineMax( this.timelineParams("#intro .part-copyout", {
      scrollTrigger: {
        start: "top bottom",
        end: "bottom top-=100%",
        scrub: true,
      }
    }) )
      .to(
        starZoomFrameRef,
        {
          currentFrame: this.dataForAnimations.starZoomData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.starZoomData.render(starZoomFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }
      )
      .set('#intro', { visibility: 'hidden'} )

    return [pinning, text_in, text_out, starZoom]
  }

  panel_pia() {

    this.tagForClear([ "#panel-pia .pia", "#panel-pia .layer-pia" ])

    /* Pinned background */
    const pin =  new TimelineMax( this.timelineParams("#panel-pia .layer-pia", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-pia",
        end:        "bottom bottom",
      }
    }) )
      .fromTo("#panel-pia .pia", { scale:1 }, { scale: 1.25 } )
    return pin
  }

  panel_moon() {

    this.tagForClear([  "#panel-moonphases .layer-moonstars",
                        "#panel-pia" , "#panel-moonphases .starfield" ])

    /* Pinned background */
    const pin =  new TimelineMax( this.timelineParams("#panel-moonphases .layer-moonstars", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-moonphases",
        end:        "bottom bottom",
      }
    }) )
      .set('#panel-pia', { visibility: 'hidden'} )


    /* Moon Phases */
    const moonPhaseFrameRef = {
      currentFrame: 0
    }

    const moonPhases = new TimelineMax( this.timelineParams("#panel-moonphases", {
      scrollTrigger: {
        start:  "top top",
        end:    "bottom bottom",
        scrub:  true,
      }
    }) )
      .set( "#panel-moonphases .starfield",  { opacity: 0, scale: 1.116 }, "0" )
      .set( "#panel-moonphases .starfield",  { opacity: 0.25 }, "0.01" )
      .to(
        moonPhaseFrameRef,
        {
          currentFrame: this.dataForAnimations.moonPhasesData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.moonPhasesData.render(moonPhaseFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }
        ,"0"
      )

      return [pin, moonPhases]
  }

  panel_dunes() {

    this.tagForClear([ "#panel-dunes .layer-dunes", "#panel-dunes .dunes" ])

    /* Pinned background */
    const pin =  new TimelineMax( this.timelineParams("#panel-dunes .layer-dunes", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-dunes",
        end:        "bottom bottom",
      }
    }) )
      .fromTo("#panel-dunes .dunes", { scale:1 }, { scale: 2, ease: "none" } )

    return pin
  }

  panel_sand() {

    this.tagForClear([ "#panel-sand .layer-sand", "#panel-sand .sand" ])

    /* Pinned background */
    const pin =  new TimelineMax( this.timelineParams("#panel-sand .layer-sand", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-sand",
        end:        "bottom bottom",
      }
    }) )
      .fromTo("#panel-sand .sand", { scale:1 }, { scale: 1.5, ease: "none" } )

    return pin
  }

  panel_risky() {

    this.tagForClear([  "#panel-risky .layer-header", "#panel-risky .layer-copy",
                        "#panel-risky .bloodcell", "#panel-risky .pluto", "#panel-risky" ])

    /* Header Fade In & Out */
    const headerInOut = new TimelineMax( this.timelineParams("#panel-risky", {
      scrollTrigger: {
        start:    "top 40%",
        end:      "top -40%",
        scrub:    true,
        duration: 2
      }
    }) )
      .set(     "#panel-risky",               { visibility: 'visible' } )
      .fromTo(  "#panel-risky .layer-header", { opacity: 0, scale: 0.8 },   { opacity: 1, scale:1, duration: 1 } )
      .set(     "#panel-risky .layer-header", { position: 'absolute' } )
      .fromTo(  "#panel-risky .layer-header", { opacity: 1 },               { opacity: 0, duration: 1 } )

    const circles = new TimelineMax( this.timelineParams("#panel-risky", {
      scrollTrigger: {
        start:  "top top",
        end:    "bottom 60%",
        scrub:  true,
      }
    }) )
      .addLabel("circles")
      .to(  "#panel-risky .pluto",      { left: '-100vw' },   "circles" )
      .to(  "#panel-risky .bloodcell",  { right: '-100vw' },  "circles" )

    return [headerInOut, circles]
  }

  panel_change() {

    this.tagForClear([  "#panel-change", "#panel-change .layer-header",
                        "#panel-change .layer-mercury" ])

    /* Header Fade In & Out */
    const headerInOut = new TimelineMax( this.timelineParams("#panel-change", {
      scrollTrigger: {
        start:    "top 40%",
        end:      "top -40%",
        scrub:    true,
        duration: 2
      }
    }) )
      .set(     "#panel-change",               { visibility: 'visible' } )
      .fromTo(  "#panel-change .layer-header", { opacity: 0, scale: 0.8 },   { opacity: 1, scale:1, duration: 1 } )
      .set(     "#panel-change .layer-header", { position: 'absolute' } )
      .fromTo(  "#panel-change .layer-header", { opacity: 1 },               { opacity: 0, duration: 1 } )

    /* Mercury Phases */
    const mercuryFrameRef = { currentFrame: 0 }
    const mercury = new TimelineMax( this.timelineParams("#panel-change .layer-mercury", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
        endTrigger: "#panel-change .layer-copy",
        end:        "bottom bottom",
        scrub:      true,
      }
    }) )
      .set( "#mercury_anim",  { opacity: 1 } )
      .to(
        mercuryFrameRef,
        {
          currentFrame: this.dataForAnimations.mercuryAnimData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.mercuryAnimData.render(mercuryFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }
      )

    return [headerInOut, mercury]
  }

  panel_lookfor() {

    this.tagForClear([  "#panel-lookfor", "#panel-lookfor .layer-solstice",
                        "#panel-lookfor .layer-header" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-lookfor", {
      scrollTrigger: {
        // markers: true,
        trigger:    "#panel-lookfor",
        start:      "top 40%",
        endTrigger: "#panel-lookfor .layer-copy",
        end:        "top bottom",
      }
    }) )
      .addLabel( "headerIn"  )
      .set(     "#panel-lookfor",                 { visibility: 'visible' },                                                    "headerIn" )
      .fromTo(  "#panel-lookfor .layer-header",   { opacity: 0, scale: 0.8 },           { opacity: 1, scale: 1, duration: 14 }, "headerIn" )
      .to(      "#panel-lookfor .layer-header",   { opacity: 1, duration: 5 } )
      .set(     "#panel-lookfor .layer-header",   { position: 'absolute', top: '50vh' } )
      .fromTo(  "#panel-lookfor .layer-solstice", { opacity:0 },                        { opacity: 1, duration: 9 },            "headerIn+=10" )

    const solsticeOut = new TimelineMax( this.timelineParams("#panel-lookfor .layer-copy", {
      scrollTrigger: {
        start:  "bottom 50%",
        end:    "bottom top",
      }
    }) )
      .to(  "#panel-lookfor .layer-solstice", { top: '-50%', ease: "none" } )

    return [headerInOut, solsticeOut]
  }

  panel_scotty() {

    this.tagForClear([ "#panel-scotty .layer-perspective", "#panel-scotty .perspective",
                        "#panel-lookfor", "#panel-bond" ])

    const pinBg = new TimelineMax( this.timelineParams("#panel-scotty .layer-perspective", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
        endTrigger: "#panel-scotty .layer-copy-3",
        end:        "bottom bottom",
      }
    }) )
      .fromTo( "#panel-scotty .perspective", { scale: 1 }, { scale: 1.5, ease: "none" } )
      /* Hide the previous panel and setup visiblity for next panel */
      .set(     "#panel-lookfor", { visibility: 'hidden' } )
      .set(     "#panel-bond",    { visibility: 'visible' } )

    return [pinBg]
  }

  panel_bond() {

    this.tagForClear([  "#panel-bond .rocket", "#panel-bond .startrail",
                        "#panel-bond .layer-pictures" ])

    const pinBg = new TimelineMax( this.timelineParams("#panel-bond .layer-copy", {
      scrollTrigger: {
        start:      "top bottom",
        endTrigger: "#panel-bond",
        end:        "bottom bottom",
      }
    }) )
      .fromTo(    "#panel-bond .rocket, #panel-bond .startrail",  { scale: 1 },                                     { scale: 1.25, ease: "none" },  "0" )
      .addLabel(  "picout")
      .set(       "#panel-bond .layer-pictures",                  { position: 'absolute', top: 'auto', bottom: 0},                                  "picout")

    return [pinBg]
  }

  panel_eclipse() {

    this.tagForClear([  "#panel-eclipse .layer-eclipse", "#panel-eclipse .eclipse" ])

    const pin = new TimelineMax( this.timelineParams("#panel-eclipse .layer-eclipse", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
        endTrigger: "#panel-eclipse",
        end:        "bottom bottom",
      }
    }) )
      .fromTo( "#panel-eclipse .eclipse", { opacity: 0 },   { opacity: 1, ease: "none", duration: 1 },    "0" )
      .fromTo( "#panel-eclipse .eclipse", { scale: 0.12 },  { scale: 1.4, ease: "none", duration: 2 },    "0" )
      .fromTo( "#panel-eclipse .eclipse", { rotate: 0 },    { rotate: 180, ease: "none", duration: 15 },  "0" )

    return [pin]
  }

  panel_verb() {

    this.tagForClear([  "#panel-verb", "#panel-verb .clip", "#panel-verb .layer-header",
                        "#panel-verb .coal", "#panel-verb .water", "#panel-verb .layer-coal-and-water" ])

    const revealPictures = new TimelineMax( this.timelineParams("#panel-verb", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "top top",
      }
    }) )
      .set(     "#panel-verb",        { visibility: 'visible' } )
      .fromTo(  "#panel-verb .clip",  { height: 0 },              { height: '100vh', ease: "none" } )

    const animatePictures = new TimelineMax( this.timelineParams("#panel-verb .layer-copy-1", {
      scrollTrigger: {
        start:      "top bottom",
        endTrigger: "#panel-verb",
        end:        "bottom bottom",
      }
    }) )
      .fromTo(  "#panel-verb .coal, #panel-verb .water",  { scale: 1 },                                     { scale: 1.5, ease: "power1.inOut" } )
      .set(     "#panel-verb .layer-coal-and-water",      { position: 'absolute', bottom:0, top: 'auto' } )

    /* Header Fade In & Out */
    const headerIn = new TimelineMax( this.timelineParams("#panel-verb", {
      scrollTrigger: {
        start:    "top 25%",
        end:      "top -50%",
        scrub:    true,
        // duration: 2
      }
    }) )
      .set(     "#panel-verb .layer-header", { visibility: 'visible' } )
      .fromTo(  "#panel-verb .layer-header", { opacity: 0, scale: 0.8 },  { opacity: 1, scale:1, duration: 4 } )

    const headerOut = new TimelineMax( this.timelineParams("#panel-verb .layer-copy-1", {
      scrollTrigger: {
        start:    "top bottom",
        end:      "top 75%",
      }
    }) )
    .fromTo(  "#panel-verb .layer-header", { y:0, opacity: 1 }, { y: '-25%', opacity: 0, ease: "none" } )

    return [revealPictures, animatePictures, headerIn, headerOut]
  }

  panel_hindsight() {

    this.tagForClear([ "#panel-hindsight", "#panel-hindsight .layer-header" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-hindsight", {
      scrollTrigger: {
        start:      "top 40%",
        endTrigger: "#panel-hindsight .layer-copy",
        end:        "top bottom",
      }
    }) )
      .set(     "#panel-hindsight",                 { visibility: 'visible' },                                                    "0" )
      .fromTo(  "#panel-hindsight .layer-header",   { opacity: 0, scale: 0.8 },           { opacity: 1, scale: 1, duration: 14 }, "0" )
      .to(      "#panel-hindsight .layer-header",   { opacity: 1, duration: 5 } )
      .set(     "#panel-hindsight .layer-header",   { position: 'absolute', top: '50vh' } )

    return [headerInOut]
  }

  panel_whatwedo() {

    this.tagForClear([ "#panel-whatwedo", "#panel-whatwedo .layer-header" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-whatwedo", {
      scrollTrigger: {
        start:      "top 40%",
        endTrigger: "#panel-whatwedo .layer-copy",
        end:        "top bottom",
      }
    }) )
      .set(     "#panel-whatwedo",                 { visibility: 'visible' },                                                    "0" )
      .fromTo(  "#panel-whatwedo .layer-header",   { opacity: 0, scale: 0.8 },           { opacity: 1, scale: 1, duration: 14 }, "0" )
      .to(      "#panel-whatwedo .layer-header",   { opacity: 1, duration: 5 } )
      .set(     "#panel-whatwedo .layer-header",   { position: 'absolute', top: '50vh' } )

    return [headerInOut]
  }

  end_screen() {

    this.tagForClear([ "#screen-end .layer-logo" ])

    const r7out = new TimelineMax( this.timelineParams("#screen-end", {
      scrollTrigger: {
        start:      "top 50%",
        end:        "top top",
      }
    }) )
      .fromTo(  "#screen-end .layer-logo", { opacity: 0 }, { opacity: 1, ease: "none" } )

    return [r7out]
  }


}

export default MobileAnimations
