import React from 'react';
import sanityClient from '@sanity/client';
import isEmpty from 'lodash/isEmpty';

import imageMissing from '../assets/img_missing.png.webp';

var jsonQuery = require('json-query');

/*
 * Singleton wrapper class to provide the app with all the needed sanity.io cms data
 */
class SanityProvider {

  /* Client internals */
  draftMode = false;
  draftModeSecret = 'sx87g@ki';
  sanityClient = null;

  /* Other internals */
  data = null;

  /* DEFS */
  UPDATES_PER_PAGE = 6;

  /* Other public vars */
  updatesCount = 0;
  blockContentSerializers = {
    marks : {
      link: props => {
        return (
        <a href={props.mark.href} className='link--in-copy' target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )}
    }
  }

  initializeClient(draftMode = false) {
    /* Already initialized ? */
    if(this.sclient) { return; }

    /* Set draftMode option */
    this.draftMode = draftMode;

    /* Setup client */
    const clientConfig = {
      projectId: process.env.REACT_APP_SANITY_PROJECT,
      dataset: process.env.REACT_APP_SANITY_DATASET,
      useCdn: false, // `false` if you want to ensure fresh data

    }
    if(this.draftMode) {
      clientConfig['token'] = 'sk0E7DxRnEA3IkediyynasMmCcqzfzQ4tAhI7RIUSkvdkBXmLbPx1vPKIkCuk3E8sZGPlTVNcHGhdKPB65lwcMVfOd0WSCR24gKmmLhjyXkQ1cNT4OxpP1Tp8XYPUQ9quT4SPv9eV88nuouAjv6tRFe4BeB6Yk6MfRrSdYYBn86QbQ0UXQEH';
      clientConfig['withCredentials'] = true;
    }
    this.sclient = sanityClient(clientConfig);
  }

  /* This should be called before any attempt to render the app (both in the server middle ware and before the call to hydrate in the client) */
  async fetchInitialData(draftMode = false) {
    this.initializeClient(draftMode);


    /* Fetch all data */
    const commonMeta = `metaTags{..., ogImage{asset->}}`;
    const globalConf = `*[_type == 'globalConf']{..., metaDefaults{..., ogImage{asset->}}}`;
    const dataFooter = `*[_type == 'footer']`;
    const dataHomePage = `
      *[_type == 'homePage']{
        case_studies[]->{..., homePageFeature{..., homePageImage{ asset-> } } },
        ${commonMeta}, ...
      }`;
    const dataStudies = `
      *[_type == 'caseStudy']{
        heroMedia[]{_type, asset->},
        logo{asset->},
        blocks[]{..., image{..., asset->}},
        ${commonMeta}, ...
      }`;
    const dataTeamMembers = `
      *[_type == 'teamMember']{
        picture{..., asset->},
        video{..., asset->},
        extra_pictures[]{..., asset->},
        ${commonMeta}, ...
      }
      +
      *[_type == 'teamLanding']{
        sections[]{..., members[]->{..., picture{..., asset->}}},
        ${commonMeta}, ...
      }`;
    const dataUpdates = this.buildUpdateFetchQuery(0);

    const initialData = [
      globalConf,
      dataFooter, dataHomePage, dataStudies,
      dataTeamMembers, dataUpdates
    ].join(' + ');

    this.data = await this.sclient.fetch(initialData);

    /* Do some calculations for the updates page */
    this.updatesCount = await this.getUpdateCount();
    this.maxUpdatePage = Math.ceil( (this.updatesCount - 1) / this.UPDATES_PER_PAGE) - 1;

    return this.data;
  }

  buildUpdateFetchQuery(page = 0) {
    const first = page === 0 ? 0 : page * this.UPDATES_PER_PAGE + 1;
    const last = first + (page === 0 ? this.UPDATES_PER_PAGE : this.UPDATES_PER_PAGE-1);
    // Using the featuredSort variables to handle the field not being present on some older updates
    return `
      *[_type=='update']{
        ...,
        "featuredSort": featured == true,
        picture{...,asset->},
        blocks[]{..., image{asset->}}
      } | order(featuredSort desc, publishedAt desc) [${first}..${last}]
    `;
  }

  /* First page is 7 entries, then only 6 entries per page */
  async getUpdatesPage(page = 0) {
    return await this.sclient.fetch(this.buildUpdateFetchQuery(page));
  }

  async getUpdateCount() {
    const possiblyExcludeDrafts = !this.draftMode ? ' && !(_id in path("drafts.**"))': '';
    const query = `count( *[_type=='update' ${possiblyExcludeDrafts}])`;
    return this.sclient.fetch(query);
  }

  /* Find a sanity object by reference (used for reference arrays, images, etc.) */
  findByRef(ref) {
    if(this.draftMode) {
      let draft = this.query(`[_id=drafts.${ref} ]`);
      if(draft)
        return draft;
    }
    return this.query(`[_id=${ref}]`);
  }

  /* Find the first sanity object by type */
  findFirstOfType(type) {
    if(this.draftMode) {
      let draft =  this.query(`[_type=${type} & _id~/drafts/]`);
      if(draft)
        return draft;
    }
    return this.query(`[_type=${type}]`);
  }

  /* Find case study */
  findCaseStudyBySlug(slug) {
    return this.findDocumentBySlug('caseStudy', slug);
  }

  /* Find team members */
  findTeamMemberBySlug(slug) {
    return this.findDocumentBySlug('teamMember', slug);
  }

  /* Find update */
  findUpdateBySlug(slug) {
    return this.findDocumentBySlug('update', slug);
  }

  findDocumentBySlug(documentType, slug) {
    const slugIs = function (item, slug) {
      return item.slug.current === slug;
    }
    if(this.draftMode) {
      let draft = this.query(`[*_type=${documentType} & _id~/drafts/][:slugIs(${slug})]`, { slugIs });
      if(draft)
        return draft;
    }
    return this.query(`[*_type=${documentType}][:slugIs(${slug})]`, { slugIs } )
  }

  findAllByTypes(documentType) {
    if(this.draftMode) {
      return this.query(`[*_type=${documentType} & _id~/drafts/]`);
    }
    return this.query(`[*_type=${documentType}]` )
  }

  /* Get the image url for an image asset (imageAssetRef must be either a ref to sanity.imageAsset or a sanity.imageAsset )
     Allow passing an object for options to be appended to the url according to the doc https://www.sanity.io/docs/image-urls
   */
  getImageUrl(imageAssetOrRef, options = {}) {

    if(!imageAssetOrRef) {
      console.warn("Empty argument in getImageUrl");
      return imageMissing;
    }

    let realAsset = imageAssetOrRef._type === 'sanity.imageAsset' ? imageAssetOrRef : this.findByRef(imageAssetOrRef?.asset?._ref);

    if(!realAsset) {
      console.warn("Image not found (reference passed)", imageAssetOrRef);
      return imageMissing;
    }

    let imageUrl = realAsset.url;
    if(!isEmpty(options)) {
      imageUrl += '?' + Object.keys(options).map( (k) =>
        `${k}=${options[k]}`
      ).join('&');
    }
    return imageUrl;
  }

  applyImageUrlOptions( imageUrl, options = {} ) {
    if(isEmpty(options)) {
      return imageUrl;
    }
    return (`${imageUrl}?` + Object.keys(options).map( (k) =>
        `${k}=${options[k]}`
      ).join('&')
    )
  }

  /* Should not be called directly to allow for draft behavior */
  query(query, locals = {}) {
    return jsonQuery(query, { data: this.data, locals: locals, allowRegexp: true } ).value;
  }

}

export default new SanityProvider();
