import React, { useEffect } from 'react';
import { motion, usePresence } from "framer-motion"

import { Link } from 'react-router-dom';

function FourOFour(props) {
  const [isPresent, safeToRemove] = usePresence();

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key={`page404`}>
      <section className='page-four-o-four grid'>
        <div className='row'>
          <div className='col-m-4 col-t-6 off-t-2 col-d-8 off-d-2'>
            <h1 className='404'>Oops, nothing to see there.</h1>
            <p><Link to='/' className="link--more">Go back to the Home Page</Link></p>
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default FourOFour;