import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter, matchPath } from "react-router";

import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import { motion } from "framer-motion"

class DesktopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /* Animated ball */
      hover: false,
      hoverSelectedLink: 0,

      /* Size of elements for animated ball calculations */
      sizes: {}
    }

    /* Reference to elements for animated ball */
    this.els = {};

    // this.resize = this.resize.bind(this);
    this.resizeThrottled = throttle(this.resize, 10).bind(this);
  }

  componentDidMount() {
    /* Calculate dimensions for animated ball */
    this.getSizes();
    window.addEventListener('resize', this.resizeThrottled);
  }

  resize() {
    if(typeof window !== "undefined" && window.innerWidth >= 768) {
      this.getSizes();
    }
  }

  /* Handles temporary override of selected link on Hover */
  hoverSelect(i) {
    this.setState({
      hover: true,
      hoverSelectedLink: i
    });
  }

  hoverUnSelect() {
    this.setState({
      hover: false,
    });
  }

  /* Calculate dimensions for animated ball */
  getSizes() {
    if(!this.root) {
      return;
    }
    const rootBounds = this.root.getBoundingClientRect();
    const sizes = {};

    Object.keys(this.els).forEach((key) => {
      const el = this.els[key];
      const bounds = el.getBoundingClientRect();

      const left = bounds.left - rootBounds.left;
      const width = bounds.width;

      sizes[key] = {left, width};
    });
    this.setState({sizes});
  }

  /* Get the index of link that should be active based on Router location */
  getActiveLinkIndexFromRoute() {
    return this.props.routes.findIndex(route => {
      return !route.external && matchPath(this.props.location.pathname, { path: route.path, exact: true } );
    });
  }

  /* Get the index of selected link (Route based on hover override) */
  getCurrentSelectedIndex() {
    return this.state.hover === true ? this.state.hoverSelectedLink : this.getActiveLinkIndexFromRoute();
  }

  /* Calculate coordinates of ball based on selected link */
  getUnderlineStyle() {
    const selectedIndex = this.getCurrentSelectedIndex();
    const size = this.state.sizes[selectedIndex];

    if(typeof size === 'undefined') {
      return;
    }

    const left = size.left + size.width / 2 - 3;

    return {
      x: left
    }
  }

  render() {
    return (
      <>
        <ul className='pages__list' ref={el => this.root = el}>
          {this.props.routes.map((route, i) =>
            <li key={i}
              ref={el => this.els[i] = el }
              onMouseEnter={() => this.hoverSelect(i) }
              onMouseLeave={() => this.hoverUnSelect(i) }
            >
              { route.external ? <a href={route.to} target="_blank" rel="noopener noreferrer">{route.text}</a>
              : <NavLink to={route.to}>{route.text}</NavLink> }

            </li>
          )}
        </ul>
        { /* Only display ball if sizes have been calculated and it matches a route */
          !isEmpty(this.state.sizes) && this.getCurrentSelectedIndex() >= 0 &&
          <motion.div className='pages__list__underdot' transition={{ ease: "easeOut", duration: .3, type: "tween" }} initial={false} animate={this.getUnderlineStyle()}/>
        }
      </>
    )
  }

}

export default withRouter(DesktopNav);