import React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ReactComponent as R7Logo } from '../../assets/r7-animated.svg';
import { NavLink } from 'react-router-dom';
import { withRouter, matchPath } from "react-router";
import Div100vh from 'react-div-100vh';
import { AnimatePresence, motion } from "framer-motion"

import { FooterCommon } from './Footer';

class MobileNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.targetElement = document.querySelector('#main');
    window.addEventListener('resize', this.close);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    window.removeEventListener('resize', this.close);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.close();
    }
  }

  open() {
    this.setState({isOpen:true});
    disableBodyScroll(this.targetElement);
  }

  close() {
    this.setState({isOpen:false});
    enableBodyScroll(this.targetElement);
  }

  toggle() {
    if(this.state.isOpen) {
      this.close();
    }
    else {
      this.open();
    }
  }



  render() {
    const variants = {
      "initial": {
        height: "0vh",
        opacity: 0,
        transition: { ease: "easeInOut", duration: .2 }
      },
      "open": {
        height: "100vh",
        opacity: 1,
        transition: { ease: "easeInOut", duration: .2 }
      }
    };

    return (
      <>
        <span className='hamburger' onClick={this.open}/>
        <AnimatePresence>
        { this.state.isOpen &&
          <motion.div animate="open" initial="initial" exit="initial" variants={variants} className='mobile-nav-takeover'>
            <Div100vh className='mobile-nav__100wrapper'>
              <div>
                <ul className='mobile-nav__top'>
                  <li className='home'>
                    <NavLink to='/'><R7Logo/></NavLink>
                  </li>
                  <li className=''>
                    <div className='close' onClick={this.close}></div>
                  </li>
                </ul>
                <ul className='mobile-nav__links'>
                  {this.props.routes.map((route, i) =>
                    <li key={i} >
                      { route.external ? <a href={route.to} target="_blank" rel="noopener noreferrer">{route.text}</a>
                        : <NavLink
                            isActive={(match, location) => {
                              return matchPath(location.pathname, {
                                path: route.path,
                                exact: route.exact
                              })
                            }}
                            to={route.to}>{route.text}</NavLink>
                      }
                    </li>
                  )}
                </ul>
              </div>
              <div className='mobile-nav__footer'>
                <FooterCommon/>
              </div>
            </Div100vh>
          </motion.div>
        }
        </AnimatePresence>
      </>
    );
  }
}

export default withRouter(MobileNav);