import React from 'react';

import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import * as Scroll from 'react-scroll';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, AnimatePresence } from "framer-motion"
import throttle from 'lodash/throttle'

import SanityHelmet from '../../common/SanityHelmet';
import R7SVG from  './subcomponents/R7Logo'
import MobileAnimations from './animations/mobileAnimations'
import TabletAnimations from './animations/tabletAnimations'
import DesktopAnimations from './animations/desktopAnimations'
import ArrowLink from '../../common/ArrowLink';

/* Images used as html elements */
import img_iss from '../../../assets/manifesto/iss059e075342_lrg-rotated-cropped.webp'
import img_moon from '../../../assets/manifesto/SDO_HMI_20120305_043000_2048_HMIIF_lrg-withtrans.webp'
import img_solarpanel from '../../../assets/manifesto/solar panel - cropped.webp'
import img_pia from '../../../assets/manifesto/pia23533.webp'
import img_pluto from '../../../assets/manifesto/plutos-haze.png'
import img_bloodcell from '../../../assets/manifesto/bloodcell.webp'
import img_solstice from '../../../assets/manifesto/summer_solstice.png'
import img_eclipse from '../../../assets/manifesto/eclipse.webp'

/* Images used in canvas animations */
import starZoomImages from './assets_helpers/starzoomImages.js'
import moonPhaseImages from './assets_helpers/moonphasesImages.js'
import mercuryImages from './assets_helpers/mercuryImages.js'
import flybyImages from './assets_helpers/flybyImages.js'

gsap.registerPlugin(ScrollTrigger);


class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { progress: 0 };
    this.updateProgressThrottled = throttle( this._updateProgress, 100, { leading: false } )
  }

  _updateProgress(progress) {
    this.setState({ progress: progress })
  }

  updateProgress(progress) {
    this.updateProgressThrottled(progress)
  }

  render() {
    const style = {
      background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) ${this.state.progress}%, rgba(0,0,0,0) ${this.state.progress}%, rgba(0,0,0,0) 100%)`
    }
    return(
      <AnimatePresence>
        { this.state.progress < 100 &&
          <motion.div id='loader' initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <R7SVG />
            <div className='progress' style={style}></div>
            <span>{this.state.progress}%</span>
          </motion.div>
        }
      </AnimatePresence>
    )
  }
}

class Manifesto extends React.Component {

  constructor(props) {
    super(props)

    /* Create refs */
    this.references = {}
    this.createRefs([
      'bgStarfield','logo','iss',
      'moon_and_panels','moon','solarpanel',
      'intro_copy1','intro_copy2','intro_copy3',
      'starfieldZoom', 'intro_out1', 'intro_out2', 'intro_out_zoom_layer','intro_out_copy_layer',
      'pia', 'moonphases', 'moonphases_starfield',
      'dunes', 'sand',
      'mercury_anim', 'flyby_anim'
    ])

    this.mounted = null

    this.assetsToLoad = 0
    this.loadedAssets = 0
    this.loaderRef =  React.createRef()

    this.updateLoaderProgress = this.updateLoaderProgress.bind(this)
    this.watchForPreload = this.watchForPreload.bind(this)
  }

  createRefs(refNames) {
    refNames.forEach( (refName) => {
      this.references[refName] = React.createRef();
    });
  }

  dataForAnimations() {
    return {
      references: this.references,
      starZoomData: this.starZoomData,
      moonPhasesData: this.moonPhasesData,
      mercuryAnimData: this.mercuryAnimData,
      flyByAnimData: this.flyByAnimData,
    }
  }
  clearAllRefsProps() {
    Object.values(this.references).forEach( (ref) => { gsap.set(ref.current, {clearProps: true}); })
  }

  instantiateAnimationsForDevice(deviceAnimations) {
    /* Create the animations through a timeout to give the DOM a chance to refresh */
    // setTimeout( () => {
    window.requestAnimationFrame( () => {
      this.currentDeviceAnimations = new deviceAnimations(this.dataForAnimations())
      this.currentDeviceAnimations.createAnimations()

      /* Scroll down a bit to trigger the beginning of the animation when ready */
      if(window.scrollY < window.innerHeight) {
        /* Since this is a callback, there's a possibility the user is not on the manifesto page anymore. Check the route */
        if(this._ismounted) {
          Scroll.animateScroll.scrollTo(this.currentDeviceAnimations.scrollAmountOnLanding, { duration: 2500 })
        }
      }
    })
    // }, 500)
  }

  killAllScrollTriggers() {
    /* Manually destroy all scrolltriggers (matchmedia seems to be failing to catch them and does not properly destroy them upon viewport resize) */
    const sts = ScrollTrigger.getAll()
    sts.forEach( (st) => { st.kill() } )

    /* And clear all the targets possibly modified by the current animation class */
    if(this.currentDeviceAnimations) {
      this.currentDeviceAnimations.clearProps()
    }

  }

  /* This is responsible for creating the gasp animations and handling breakpoints */
  createAnimations() {
    /* Create Animations */
    ScrollTrigger.matchMedia({
      "(max-width: 767px)": () => {
        this.instantiateAnimationsForDevice(MobileAnimations)
        return () => { this.killAllScrollTriggers() }
      },
      "(min-width: 768px) and (max-width: 1023px)": () => {
        this.instantiateAnimationsForDevice(TabletAnimations)
        return () => { this.killAllScrollTriggers() }
      },
      "(min-width: 1024px)": () => {
        this.instantiateAnimationsForDevice(DesktopAnimations)
        return () => { this.killAllScrollTriggers() }
      }
    });
  }

  loadImagesForCanvasAnim(image_assets, canvas_ref_name) {
    /* SSR Guard */
    if(typeof(window) === 'undefined')
      return;

    const frameCount = image_assets.length
    const canvas = this.references[canvas_ref_name].current
    const context = canvas.getContext("2d")

    /* Pre-Load */
    const images = []
    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      images.push(img);
    }
    // for (let i = 0; i < frameCount; i++) {
    //   const img = new Image();
    //   img.src = image_assets[i]
    //   images.push(img);
    //   img.addEventListener('load', this.updateLoaderProgress)
    // }

    // this.assetsToLoad = this.assetsToLoad + images.length
    this.assetsToLoad = this.assetsToLoad + frameCount


    const render = (index) => {
      context.imageSmoothingQuality = "high"
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(images[index], 0, 0, canvas.width, canvas.height);
    }

    const preload = () => {
      for (let i = 0; i < frameCount; i++) {
        images[i].addEventListener('load', this.updateLoaderProgress)
        images[i].src = image_assets[i]
      }
    /* Add event listener to render first frame upon loading the first image to make sure canvas is not blank when scrolling into view */
    images[0].addEventListener('load', () => { render(0) })
    }

    /* Data to pass to animation builder */
    const data = {
      frameCount: images.length - 1,
      render: render,
      preload: preload
    }

    return data
  }

  /* This will only trigger the creation of animations when the DOM images will have finished preloading */
  watchForPreload() {
    this.preload = this.preload - 1
    if(this.preload === 0) {
      // console.log("Creating anims")
      this.createAnimations();
      // console.log("Preloading stage 2")
      this.starZoomData.preload()
      this.moonPhasesData.preload()
      this.mercuryAnimData.preload()
      this.flyByAnimData.preload()

    }
  }

  updateLoaderProgress(obj) {
    this.loadedAssets = this.loadedAssets + 1
    // console.log("loaded", this.loadedAssets, "total", this.assetsToLoad, obj.target.src)
    const progress = Math.round( 100 * this.loadedAssets / this.assetsToLoad )
    // this.setState({progress: progress})
    // console.log(this.loaderRef)
    if(this.loaderRef.current && this._ismounted) {
      this.loaderRef.current.updateProgress(progress)
    }
  }

  componentDidMount() {
    this._ismounted = true;

    /* Preload animation images */
    this.starZoomData = this.loadImagesForCanvasAnim(starZoomImages, "starfieldZoom")
    this.moonPhasesData = this.loadImagesForCanvasAnim(moonPhaseImages, "moonphases")
    this.mercuryAnimData = this.loadImagesForCanvasAnim(mercuryImages, "mercury_anim")
    this.flyByAnimData = this.loadImagesForCanvasAnim(flybyImages, "flyby_anim")

    /* Preload inline images - this has to be done before creating animations so that the scroll triggers timelines can do their dom calculations */
    // console.log("Preloading stage 1")
    this.preload = 8;
    this.assetsToLoad = this.assetsToLoad + 8;
    [img_iss, img_moon, img_solarpanel, img_pia, img_pluto, img_bloodcell, img_solstice, img_eclipse].forEach( (imgsrc) => {
      const img = new Image()
      img.addEventListener('load', this.watchForPreload )
      img.addEventListener('load', this.updateLoaderProgress)
      img.src = imgsrc
    })
  }

  componentWillUnmount() {
    this._ismounted = false;

    /* Cleanup gsap */
    this.killAllScrollTriggers()

    /* Remove all media query callbacks */
    ScrollTrigger.clearMatchMedia("(max-width: 767px)")
    ScrollTrigger.clearMatchMedia("(min-width: 768px) and (max-width: 1023px)")
    ScrollTrigger.clearMatchMedia("(min-width: 1024px)")
  }

  render() {

    const overrideOgTags = {
      title: "Manifesto | R7",
      ogTitle: "Manifesto"
    }

    return (
      <div id='manifesto-wrap'>
        <SanityHelmet documentTags={overrideOgTags}/>
        <Loader ref={this.loaderRef}/>
        <div id="manifesto" style={{opacity: 0}}>

          <div id='intro'>
            <div className='part-spacelogo'>
              <div className='layer-starfield'><div ref={this.references.bgStarfield} className='bg-starfield'></div></div>
              <div className='layer-iss'><div className='iss' ref={this.references.iss}><img src={img_iss} alt='iss'/></div></div>
              <div className='layer-logo'><div className='logo' ref={this.references.logo}><R7SVG /></div></div>
            </div>

            <div className='part-moonpanel'>
              <div className='moon_and_panels' ref={this.references.moon_and_panels}>
                <div className='moon' ref={this.references.moon}><img src={img_moon} alt='iss'/></div>
                <div ref={this.references.solarpanel} className='solar-panel'><img src={img_solarpanel} alt='solar panel'/></div>
              </div>
              <div className='copy'>
                <div className='layer-copy'>
                  <div ref={this.references.intro_copy1} className='copy1'>OUR MANIFESTO</div>
                  <h1 ref={this.references.intro_copy2} className='copy2'>Solving Real Problems Yields Incredible Results</h1>
                  <div ref={this.references.intro_copy3} className='copy3'>
                    SEPT. 18, 2013
                  </div>
                </div>
              </div>
            </div>

            <div className='part-copyout'>
              <div className='layer-starzoom'ref={this.references.intro_out_zoom_layer}>
                <canvas id='starfieldZoom' ref={this.references.starfieldZoom} width='2000' height='2000'/>
              </div>
              <div className='layer-copy' ref={this.references.intro_out_copy_layer}>
                <div className='copy'>
                  <h3 ref={this.references.intro_out1} className='copy_out1'>Where Have all the Venture Capitalists Gone?</h3>
                  <blockquote ref={this.references.intro_out2} className='copy_out2'>“We’re gambling on our vision, and we would rather do that than make “me too” products.
                    Let some other companies do that. For us, it’s always the next dream.”<span>— Steve Jobs</span>
                  </blockquote>
                </div>
              </div>

              <div className='track'>
              </div>
            </div>
          </div>


          <div id='panels'>

            <div id='panel-pia'>
              <div className='layer-pia'><div className='pia' ref={this.references.pia}><img src={img_pia} alt='pia'/></div></div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <p className='with-initial'>
                      We invest in extraordinary teams solving real, often very difficult, problems with transformational technologies. We do this because it’s those successful
                      innovations that become disruptive and fundamentally change our world, yielding incredible results for society, but also particularly for those who fund the
                      innovations.</p>
                    <p>
                      Today, we create as much information in 48 hours as we did from the dawn of man through 2003. A modern cell phone has more computing power than all of NASA in
                      1969, the year we first placed two men on the moon. Pacemakers save more lives each day than the total number of men, women and children living in the state of
                      Iowa. Each year, FedEx ships a total weight equivalent to 1,411,548 Nissan Altimas to all corners of the globe. If Facebook’s active users formed a nation, it
                      would be the world’s third-largest country.</p>
                    <p>
                      All these results were made possible by venture capital. Kids used to grow up dreaming of being “like Jordan.” However, today, they want to be “like Zuckerberg.”
                      We’ve traded Michael for Mark — swapped a transcendent athlete with the geeky 27-year-old face of the social media revolution.</p>
                    <p>From the emergence of venture capital as an asset class until 1998, the industry provided some of the greatest innovations — and corresponding returns — imaginable.
                      It was the result of an ambitious few attempting to solve difficult problems that most believed to be impossible. It was VC’s heyday — a time of real innovation.
                      However, the dotcom bubble that made venture capital so notoriously famous also fundamentally transformed the industry’s very psyche and has spawned a decade or
                      more of general discouragement and corresponding poor returns.</p>
                    <p>So what happened? Let us explain.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-moonphases'>
              <div className='layer-starfield'><div ref={this.references.moonphases_starfield} className='starfield'></div></div>
              <div className='layer-moonstars'>
                <div className='layer-moonphases'>
                  <div className='squarewrap'>
                    <canvas id='moonphases' ref={this.references.moonphases} width='1000' height='1000'/>
                  </div>
                </div>
              </div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap tab-left dsk-left'>
                    <h3>Style Drifting</h3>
                    <p>
                      A general misconception of the past 12 years is that the venture capital industry is flooded with too much money and too few companies, and that it is this situation
                      above all else that has led to poor returns.</p>
                    <p>
                      However, pulling back the curtain reveals a significantly different picture. From 1980 through 2008, venture firms invested a relatively consistent amount of capital
                      per year, expressed as a percentage of the total stock market value (0.164% to be exact). Furthermore, since 2002, these investments have run slightly below the
                      historical average while the number of companies invested in by venture firms has remained relatively stable.</p>
                    <p>
                      The VC industry has been, in fact, remarkably consistent over the past 30 years. So its recent troubles are not due to too much money and too few companies. Nor can
                      they be entirely explained away by such government-imposed regulations as Sarbanes-Oxley, which others have accused of damaging the IPO market and diminishing
                      returns for venture-backed companies. No, the real answer is much more inherent and pervasive.</p>
                    <p>
                      The root of venture capital’s problem, in fact, is the manner in which the majority of today’s investors behave. Now more than ever, the focus is squarely on returns,
                      and this single-mindedness leads to disproportionate investments in those ideas perceived to be “safer.” Accordingly, early market leaders are now attracting a
                      bandwagon of VCs at ever-increasing valuations while entrepreneurs are responding by building low-tech replicas that exist, rather unconstructively, on their high
                      probability of being funded.</p>
                    <p>
                      We believe the venture industry is suffering from a singular, clear-cut malady; aversion to risk. And we’ve identified two clear symptoms of this problem which we
                      call the Backstretch Bandwagon and Double Stuf Oreo Effect.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-dunes'>
              <div className='layer-dunes'><div className='dunes' ref={this.references.dunes}></div></div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <h3>Back Stretch Bandwagon</h3>
                    <p>Consider the sequence 1, 2, 4 and 10: these are the number of venture firms that invested, per round, in Groupon.<br/>
                      Now, consider the sequence 5, 30, 135 and 950: these represent the total capital dollars per round, in millions, invested in Groupon by those 17 venture
                      firms over a four-year period.</p>
                    <p>This phenomenon began with the early successes of the dotcom boom, which created a whole host of followers and visionless copycats trying to get in on the
                      action. The more startup companies that entered a market, the more pronounced the process became. Venture firms (self-declared in many instances) popped up
                      overnight to capitalize on this boom and quickly invested in the fad-of-the-moment companies, many which were “hot.” They discarded ambitious innovations
                      that took time to build in exchange for companies that could go public in the fastest manner possible, figuring out how to make money later. During the
                      dotcom boom, venture firms were throwing money — and lots of it — into everything.</p>
                    <p>Today, most investors are piling into early market leaders like a pack of 16-year-old girls trying to get into a Justin Bieber concert, dispensing
                      inordinate amounts of funding under the illusion of limited risk and a quick return. This backward approach — akin to betting big money on a horse
                      who’s already leading in the backstretch — has infected the behavior of too many venture investors and deterred them from pursuing riskier, lengthier,
                      more ambitious ideas from the get-go. Many of the early market leaders, like Groupon, have raised extravagant sums of capital at ridiculous valuations
                      just before filing an IPO, mainly because venture firms saw them as safe and hoped to boost their profiles by adding a logo to their websites.</p>
                    <p>The act of more and more venture firms investing larger and larger amounts of capital, often at absurd valuations, in perceived early market leaders
                      is a relatively new phenomenon. The philosophy is simple: whoever has early momentum in a market and is receiving the most attention in the form of
                      capital and media (e.g., Dropbox or Twitter) is perceived to be a “safer” investment and a “sure thing.” Today’s venture firms invest with the intent
                      to reduce risk and hopefully juice portfolio returns without any regard for such key factors as valuation or stage of the company. At these absurd
                      valuations, most are hoping the hype pays off and money will be earned. But let’s stop calling it venture capital when it’s not. Venture capital was
                      not designed to invest in companies at a $52 billion valuation; it’s about taking ambitious leaps at earlier stages when the rest of the world
                      remains in doubt.<br/>
                      There is no long-term viability or sustainability in playing it safe. It’s simply jumping on the “Backstretch Bandwagon” and the strategy
                      adds nothing for the future of venture capital.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-sand'>
              <div className='layer-sand'><div className='sand' ref={this.references.sand}></div></div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap tab-left dsk-center'>
                    <h3>Double Stuf Oreos<br/> Don’t Change the World</h3>
                    <p>When they’re done plowing money into early market leaders, today’s venture firms are more likely to invest in non-transformational companies — ones built
                       upon minor technological advancements or ones that address trivial problems. Basically, the ones who are tinkering. Like the Dollar Shave Club, which
                       attracted not one, but a few luminary investors to finance its bold dream of selling razor blades through the mail for $1 a month.</p>
                    <p>Companies who tinker win funding because the perception is that it is easier and less risky to take small pieces of an established market, grow quickly,
                      and get out. These ideas represent, in terms of innovation, little more than adding additional white stuff between two chocolate wafers.</p>
                    <p>The fundamental problem is that tinkering is too often a doomed strategy for startups. Tinkering, in fact, carries a lot of risk . . . more than most
                      people think. But the general perception is that it carries little to no risk, and this common perception discourages entrepreneurs from doing what
                      they can achieve. This results in entrepreneurs setting their sights on less ambitious goals and scales back their ideas in order to receive funding,
                      stifling the velocity of innovation — going against the very essence of venture capital.</p>
                    <p>Consider this, Presto Services raised tens of millions in venture funding (from some very prominent firms, we might add) to create a rebranded
                      Hewlett-Packard printer that could print emails for technologically inept seniors. Similarly, Optiva attracted nearly $50 million to build a new type
                      of laminated film for flat screen TVs that could not even be used because the product was obsolete by the time it came to market. To investors, both
                      ideas seemed easy and safe, promising immediate market appeal upon launch.</p>
                    <p>While it’s possible that companies who tinker could make investors a short-term profit, but they need to be extremely lucky, particularly on timing,
                      and the return will never match the risk (or, like in the cases of Presto and Optiva, they may crash and burn quickly). This is why tinkering carries
                      much more risk than most think. Simply put, investors are playing a high-stakes game of roulette, expecting to beat the house in the short-term with
                      ideas that really were lost from the beginning.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-risky'>
              <div className='layer-circles'>
                <img className='pluto' src={img_pluto} alt='pluto'/>
                <div className='bloodcell-mask-rot'/>
                <img className='bloodcell' src={img_bloodcell} alt='bloodcell'/>
              </div>
              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>Less Risky Objectives Actually<br/>Means More Risky Behavior</h2>
                  </div>
                </div>
              </div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap'>
                    <p className='with-initial'>What most investors don’t understand is that a lot 		of risk remains in any type of startup — one small 		slip-up, and a fast
                      downward spiral can occur. Companies that tinker do so for a reason — they believe it’s easier to succeed — but it’s a delusion to think that their success
                      will ever be grand. They are competing with dozens, if not hundreds, of other companies in the same race. And it’s easy to see how a Backstretch Bandwagon
                      can create illusions of mass success when backing a brand name, but often the real venture returns have already been made and those entering into it have
                      limited upside remaining while still retaining a lot of risk. In both cases, Backstretch Bandwagons and tinkering are not long-term answers. All they
                      really do is limit the upside potential in the name of reducing risk. The fact is that Backstretch Bandwagons and tinkering do not really reduce the risk,
                      they just mask it behind a veil of perceived safety.</p>
                    <p>Even Facebook’s massive IPO will not be able to pull the industry out of this weak performance period. So unless VCs are willing to take the correct path —
                      supporting innovation that leads to the exceptional but remote place where tectonic shifts fundamentally alter the ways in which we live our lives and run our
                      businesses — history will be doomed to repeat itself.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-change'>
              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>What Needs To Change</h2>
                  </div>
                </div>
              </div>
              <div className='layer-starfield'>
                <div className='starfield'></div>
              </div>
              <div className='layer-mercury'>
                <div className='squarewrap'>
                  <canvas id='mercury_anim' ref={this.references.mercury_anim} width='1000' height='1000'/>
                </div>
              </div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap tab-left dsk-left'>
                    <p className='with-initial'>The result of the industry’s behavior is that, over the past decade, a fundamental drift in style has created a large, underserved
                      market opportunity: to find, fund and support truly transformational technologies — the ones attempting to solve real problems. The success will result in
                      disruptive companies, those who fundamentally change the ways in which we live our lives and run our businesses.</p>
                    <p>Unfortunately, greatness in its infancy is often overshadowed by ignorance.</p>
                    <p>“The horse is here to stay, but the automobile is only a novelty — a fad,” said the president of the Michigan Savings Bank, advising Henry Ford’s lawyer,
                      Horace Rackham, not to invest in the Ford Motor Co. in 1903. Rackham ignored the bank’s advice and backed the automaker; he had faith in Ford’s world-changing
                      solution. After all, Rackham was Ford’s lawyer.<br/>
                      In 1936, the New York Times published reports that “a rocket would never be able to leave the earth’s
                      atmosphere.” In 1943, Tom Watson, the chairman of IBM, predicted there was a world market for maybe five computers. In 1880, Henry Morton, the president
                      of Stevens Institute of Technology and one of the brightest minds of the time, predicted that this device invented by Thomas Edison called a “light bulb”
                      would be a conspicuous failure.</p>
                    <p>Until a problem has been solved, nobody really understands that it even exists. So even when innovations emerge, many experts will continue to doubt their
                      merit (as was the case with the Michigan Savings Bank, New York Times, Tom Watson and Henry Morton). It takes creative entrepreneurs to define a problem
                      and to prove they can solve it. The biggest opportunities exist where real innovation is needed, and oftentimes, these opportunities are overlooked, ignored
                      and outside the mainstream. Because of that, it tends to require someone from outside the market to see the problem, solve it and build a long-lasting
                      solution. Who thought we needed another search engine in 1999 created by two college students or a new smartphone in 2007 created by a computer company?</p>
                  </div>
                  <div className='white-wrap tab-right dsk-right'>
                    <p>This is why we believe in entrepreneurs who seek to build something ambitious, entrepreneurs with the determination to create a long-lasting solution
                      to a real but as-yet-unidentified problem. These are the sorts of ideas that can become verbs in our English language. If you want to figure out why the
                      can opener was invented 48 years after the sealed can (yes it actually took that long — unbelievable!), you “Google it.” When you need to overnight the new
                      Roboraptor to your seven-year-old nephew for his birthday, you “FedEx it.” When you bump into an old classmate, all you need to say is, “Facebook me.”</p>
                    <p>We bet you can’t tell us who mass-produced the second automobile, but you probably can easily recall who was first and, most likely, the model. Ford,
                      apparently without the help of Michigan Savings Bank, dramatically transformed the car industry by introducing the first moving assembly line, radically
                      reducing chassis assembly time from 12.5 hours to just 1.5 hours. One hundred years later, we still manufacture cars the same way.</p>
                    <p>But not all ambitious innovation is dead. What Henry Ford did to auto manufacturing a century ago, Bloom Energy is doing to the electrical grid today.
                      Bloom has developed a unique on-site power generation system that utilizes an innovative new fuel cell technology. Much like the car industry in the early
                      1900s, the utility industry is inefficient, fragmented and slow to change. However, Bloom is decentralizing an inefficient and massively tangled power
                      grid and changing the way the world generates and consumes energy. It’s the iconoclasts like Ford Motors a century ago and Bloom Energy today that
                      fundamentally change our world.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-lookfor'>
              <div className='layer-starfield'><div className='starfield'></div></div>

              <div className='layer-solstice'>
                <div className='solstice'><img src={img_solstice} alt='solstice'/></div>
              </div>
              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>So What Do We Look For?</h2>
                  </div>
                </div>
              </div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap'>
                    <p className='with-initial'>Companies that change the world tend to exhibit similar traits and follow related paths. Based on our experience, they each:</p>
                    <ul>
                      <li>Have truly transformational technology</li>
                      <li>Are deeply passionate about what they do</li>
                      <li>Assemble a great team, not just a good team</li>
                      <li>Begin with a laser-like focus on just one thing</li>
                      <li>Minimize behavioral change of the consumer</li>
                      <li>Understand their opportunity and disregard the market size</li>
                      <li>Build with patience, understanding that great things don’t come quickly</li>
                    </ul>
                    <p>This list is by no means exhaustive, as financial, intellectual defense and other metrics are very important. But getting these seven details right
                      provides companies with a strong base for success. And with success, companies that change the world often drive the best returns.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-scotty'>
              <div className='layer-perspective'>
                <div className='perspective'>

                </div>
              </div>
              <div className='layer-copy grid layer-copy-1'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <h3>Beam Me Up, Scotty</h3>
                    <p>"You didn’t need to be a fan of it. In fact, many of us weren’t. But its legacy lives on in comic strips, computer games, rap verses and even
                      congressional rants. Even NASA paid homage by naming a space shuttle after one of the show’s mythical ships.</p>
                    <p>Star Trek evoked far-away places never before contemplated on a mass scale. Three years after it debuted, we landed a man on the moon. But the
                      space race also spawned an unintended and spectacular side effect: the idea of mass-scale, decentralized power. The concept was inspiring: the
                      possibility of powering remote villages around the world, bringing electricity and light to those who need it most — and doing so at a sustainable
                      cost. The engineers who ran NASA’s ambitious project to convert Martian atmospheric gases to oxygen for propulsion and life support went on to build
                      their own company after they soon realized that the Mars project’s impact on earth could be far greater.</p>
                    <p>After 162 years of research and billions in funding, fuel cells still only worked on a small scale, remaining elusive as a cost-efficient,
                      mass-market energy source.</p>
                    <p>But taking Thomas Edison as inspiration, — “I have not failed. I’ve just found 10,000 ways that won’t work” — Bloom Energy defied the fuel
                      cell skeptics, achieving the result that had tormented all who’d tried before. Today they are on the cusp of surpassing the cost of conventional
                      forms of energy and close to realizing their dream of power for all. Stepping outside the shackles of the knowledgeable mind is the only place
                      ambition can be achieved. Ambition is not doing what conventional science or the experts tell you. It’s being able to tackle a problem, even
                      after 162 years of failure, and changing the world. You can say the inspiration came from Star Trek or from NASA. But wherever it came from,
                      Bloom Energy is well on the way to providing us with a map to a place once considered a far-away frontier.</p>
                    <p>We may never reach the point of “Beam me up, Scotty,” but we don’t see any problem in trying."</p>
                  </div>
                </div>
              </div>

              <div className='layer-starfield'>
                <div className='starfield'></div>
              </div>

              <div className='layer-copy grid layer-copy-2'>
                <div className='row'>
                  <div className='white-wrap'>
                    <h3>250,000 People, No Internet and One Dream</h3>
                      <p>Simon Sinek said it perfectly: “There are leaders, and then there are those who lead. Leaders hold a position of authority. But those who lead
                        inspire us with their passion. Whether they’re individuals or organizations, we follow those who lead, not because we have to, but because we want
                        too. We follow those who lead, not for them, but for ourselves.”</p>
                      <p>It’s those who begin with a strong notion of why they are doing something — their passion — who create the ability to inspire those around them.
                        They lead our world into change. And in the end, they remain incredibly successful for a very long time.</p>
                      <p>Sinek continued: “In the summer of 1963, 250,000 people showed up at the Lincoln Memorial in Washington D.C. to hear Dr. Martin Luther King, Jr.,
                        speak. No invitations were sent out. The Internet hadn’t been invented, so you couldn’t check a website for the date or location. So how on earth
                        did this many people figure this out? Dr. King wasn’t America’s only great preacher. He wasn’t the only man in pre-civil-rights America who had
                        suffered. But he had a gift. He didn’t go around enumerating a list of things that needed to change in America; he went around telling people
                        what he believed. ‘I believe, I believe, I believe,’ he told people.</p>
                      <p>“People who believed in these same things took his cause and made it their own. They told people, and those people told people. Some of those
                        people even created new structures to get the word out to even more people. But most importantly, they came because they understood why Dr. King
                        was doing what he was doing. And those 250,000 people believed the same thing that he did. That’s why they came. Not for him, but for themselves.”</p>
                      <p>We’re not looking for entrepreneurs who say, “I have a plan.” We’re looking for the ones who believe in something so strongly that everyone around
                        them can feel it too — the ones who say, “I have a dream.”</p>
                  </div>
                </div>
              </div>

              <div className='layer-copy grid layer-copy-3'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <h3>Be A Clock Builder, Not A Time Teller</h3>
                      <p>The vision is simple: to revolutionize the way every human views and interacts with media. Right now, headgear and holographic displays provide
                        high quality visuals to the user, but they don’t change the ways we interact with the world around us.</p>
                      <p>Now more than ever, the market is demanding that someone introduce new technology into displays that take the billions of images we see
                        to the next level. And one company, Light Field Lab — founded by holograph industry visionaries Jon Karafin, Brendan Bevensee and Ed Ibe — has assembled a
                        portfolio of patented technologies with a team of engineers that rivals other technologies developed by some of the largest tech companies on the planet.
                        Light Field Lab is attacking the problem with a high-functioning, collaborative group. They’re merging individual talents and skills into a super-performing
                        unit that outpaces the innovative abilities of even its most talented member.</p>
                      <p>By building this great team, Light Field Lab has now developed breakthrough optical display technologies that will completely change how people interact with their
                        devices. Once Light Field Lab becomes part of your everyday life, you’ll wonder how you ever lived without it.</p>
                      <p>Light Field Lab doesn’t just want to tell the time that lasts a generation. They want to build a clock. A team that tells time for decades."</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-bond'>
              <div className='layer-pictures'>
                <div className='rocket-wrap'><div className='rocket'></div></div>
                <div className='startrail-wrap'><div className='startrail'></div></div>
              </div>
              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap flush-left'>
                    <h3>"No Mr. Bond, I Expect You To Die!"</h3>
                      <p>"This is what Mr. Goldfinger tells James Bond when he has him strapped to a table with a laser bearing down on him (all, of course, before Bond’s
                        inevitable escape). A laser is an intensely focused beam of light . . . and there’s a reason we are about to tell you the next story.</p>
                      <p>They began at a well-known national university in the mid-1990s, focused on optimizing search algorithms and architecture. By 1999, they’d spun
                        out on their own, focusing on the search engine market. By 2002, they had indexed more sites than any other website in existence, with constantly
                        updating results — something users badly craved. By 2003, they’d been sold, and in 2011 they were shut down.</p>
                      <p>Until that last sentence, you probably thought we were talking about Google. And until then, we were. But we were also talking about Alltheweb,
                        a Norwegian search engine that, for a brief period, had all the makings of something truly great. Alltheweb eventually failed because they
                        couldn’t concentrate all their efforts on doing one thing great. They lacked focus and tried too many things too quickly, believing they
                        could put search — their original product — on autopilot and move on to other endeavors. Google succeeded because they focused exclusively
                        on search and user experience, keeping their website clean and user friendly. There’s a reason why just about every search engine still
                        alive today uses Google’s original, clean design.</p>
                      <p>The really successful companies often focus initially on one thing and doing that one thing great before expanding (just like Google). Too
                        often, young companies try to capture as much of a market as quickly as possible, doing too much before really understanding their own
                        ecosystem. There is no reason to make a startup more difficult or risky than it already is. We’re not so naïve as to think the road is
                        straight between points A and B. Markets change quickly. Entrepreneurs are ambitious by nature and are always tempted to take on too much.
                        It is our job to help channel all of these desires and ambitions into one streamlined, long-term goal built around a clear vision. We want
                        to understand our markets so well and execute on ideas so well that the ecosystem develops seamlessly, as it does in nature.</p>
                      <p>If companies cannot focus like a laser, they too can expect to die."</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-eclipse'>
              {/* <div className='layer-starfield'><div className='starfield'></div></div> */}
              <div className='layer-eclipse'><img className='eclipse' src={img_eclipse} alt='eclipse'/></div>
              <div className='layer-copy grid layer-copy-1'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <h3>Puzzle Pieces</h3>
                    <p>"Everything was set up perfectly: the right place, the right time. Email was becoming widespread, and cellphone use had exploded. Very little
                      behavioral change was necessary for people to use it. All somebody needed to do was piece it together. That’s when RIM did something so simple,
                      yet so profound — combining email and cellphones into a single package — and triggered one of the most important changes we live with today.
                      Unfathomable only 12 years ago when feature phones commanded 100% market share, smartphones now account for 63% of the U.S. cell phone market .
                      But how did such a revolutionary change happen so rapidly?</p>
                    <p>The answer is that most people hate change. No construction project gets built without some protest, and not because the neighbors don’t think
                      it looks great; because they are afraid of change. They make up excuses about traffic and increased crime, but what they’re really afraid of is
                      the unknown. There’s no difference when we’re buying the newest gadget or new software for our firm. We’re comfortable with what we know, and a
                       majority of people feel the same. Ushering in revolutionary change is difficult. The only way to overcome that, from a business perspective, is
                       to do what RIM did: build products that don’t change our habits and behaviors in such a way that they disrupt our everyday lives.</p>
                    <p>We believe Proterra is in the right place at the right time, like RIM was in 1999. They’re combining new technologies with old infrastructure,
                      into a product that requires little behavioral change. Until recently, electric vehicles — cars and buses — were limited mainly by battery
                      technology and software. What Proterra has done is help maintain the public transportation infrastructure for large metropolitan areas while
                      greatly improving emissions, reducing noise pollution, and improving fleet serviceability. The only change is that there is no change. Cities
                      still order new buses, drivers still drive them on the same routes, and commuters still board them the same way. It’s something so simple, yet
                      potentially revolutionary."</p>
                  </div>
                </div>
              </div>
              <div className='layer-copy grid layer-copy-2'>
                <div className='row'>
                  <div className='white-wrap'>
                    <h3>It's Not About A Billion Dollars, It's About Impacting A Billion People</h3>
                    <p>"The idea was one of a kind, something they believed everybody in the world would use. It could convert your smartphone from a time killer to a
                      time saver — it was Google for your life, your memory offloaded to a server. The most ardent early adopters lived in Silicon Valley, and customers
                      who abandoned the service often returned, something that rarely happens. So why was this idea avoided like the plague by the venture community?
                      Why, when they were so furiously seeking funding, did it require a 3 a.m. cash lifeline, one day before they were going to have to shut down,
                      just to save them?</p>
                    <p>Evernote didn’t want to capture every dollar in their nascent market in the shortest amount of time. They didn’t want to answer the question,
                      “Show me how I can get a 10x return on my money?” Instead, they capitalized on an opportunity — showing users how the product could improve
                      their lives. They focused on building a company that would be around for 100 years. Rather than enticing users to their service with an inferior
                      “intro” product so that they could then sell them an expensive full version later, Evernote created a robust intro product that users would love,
                      one they could collect on for the next 100 years. And they were right to do so.</p>
                    <p>Only after a fan of the service provided them with much-needed cash, enabling them to grow their user base and revenues, did they begin to
                      attract the attention of venture firms. Evernote now has more than 20 million users and has raised over $95 million dollars, namely because
                      the founder doesn’t ever want to run out of cash again. It’s not always about trying to estimate a market’s size and gaining as much share
                      as possible in the shortest amount of time. By focusing on the long-term opportunity, amazing things can happen. If the opportunity is truly
                      large, the long-term return metrics will often prove just as massive."</p>
                  </div>
                </div>
              </div>
              <div className='layer-copy grid layer-copy-3'>
                <div className='row'>
                  <div className='white-wrap tab-right dsk-right'>
                    <h3>All About The Long-Term</h3>
                    <p>"It’s one of the only tech companies with low margins, and yet somehow this has become its greatest competitive advantage. When your margins are
                      low, you need to do everything right. With every returned item, with every phone ringing at a call center, the margins slip away. Learning to do
                      everything right takes time and a lot of patience. But it builds phenomenal expertise and efficiency.</p>
                    <p>Very soon after Amazon.com was founded, it became the largest book reseller on the planet. Its brick and mortar competitors were still cash-flow
                      positive and had much higher margins. It took seven years for Amazon to become profitable, and investors were not always happy. But if you read the
                      1997 shareholder letter, you knew they didn’t operate on short-term plans, as most companies do. Amazon was operating on five- to seven-year plans.
                      Focusing on longer-term plans requires patience from both investors and employees. Few companies are willing to do it. But Amazon wasn’t focused on
                      catching the leaders of their market from behind. Rather, the goal was to become the largest internet retailer in the world, and doing so on low
                      margins — in the face of shareholder pressure, profitable competitors and frustrating setbacks — would take time. But they weren’t trying to be
                      everyone else. They were Amazon.</p>
                    <p>If a company is required to try and catch the leader in its market and along the way abandons the long-term goals set out at its founding, then
                      it likely shouldn’t have been funded in the first place. When the idea is ambitious and the problem that requires solving is as difficult as it
                      looks, a company needs to stick to the plan. Because when that company finally hits it, it will take years for the competition to catch up. It
                      takes years to become an overnight sensation. Have patience. <br/>Have faith."</p>
                  </div>
                </div>
              </div>
            </div>

            <div id='panel-verb'>

              <div className='layer-coal-and-water'>
                <div className='clip'>
                  <div className='water-wrap'><div className='water'></div></div>
                  <div className='coal-wrap'><div className='coal'></div></div>
                </div>
              </div>

              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>Creating the Next Verb</h2>
                  </div>
                </div>
              </div>

              <div className='layer-copy grid layer-copy-1'>
                <div className='row'>
                  <div className='white-wrap tab-left'>
                    <p className='with-initial'>Will we ever “Bloom” it? Or pull a Light Field Lab? We certainly think that each has the right ingredients to become a part of our
                    culture 10 years from now. Time will tell if they achieve their lofty goals. But here’s something we’re far more certain of: the companies that exhibit
                    the seven traits previously enumerated will be in the best position to become truly disruptive and someday leave a legacy behind.</p>
                    <p>We want companies that are ambitious and have the ability to create their own ecosystem. We don’t just look for these companies so that we can add
                      their logos to our website when they “make it.” We take chances on the companies that move us, the companies that make us believe in what they are
                      doing, the companies that will have a broad, positive impact on the world. We don’t want the company that can tell us the time — they’re a dime a
                      dozen. We want the company that is willing to spend the time and effort required to build a clock the right way.</p>
                    <p>We don’t ask companies to show us a tenfold return on our money. We ask them how many people can benefit from their product. One is a selfish desire,
                      the other a long-term value. And we’re in it for the value. We certainly don’t want companies that try to conquer the world all at once. We want
                      companies that focus on doing one thing great and relentlessly improve it until it’s perfect. And the easiest path to perfection is making sure that
                      products and services aren’t seen as “change” — the most feared six-letter word in the English Language.</p>
                  </div>
                </div>
              </div>

              <div className='layer-copy grid layer-copy-2'>
                <div className='row'>
                  <div className='white-wrap tab-right'>
                      <p>If we find this company, we’re not concerned about checking the box that says it fits into some known, existing market. In fact, we hope it
                        doesn’t, because a company like that has an opportunity so large in front of it that it could spawn an entire new ecosystem around it. New markets
                        don’t emerge overnight; we know this and believe in taking the time to get it just right. Even when the problem has been identified, the experts
                        can remain skeptical. There will be difficult times, but with the right plan and a timeline built on a strong foundation of patience, a true
                        visionary can turn early disadvantages into an unbeatable competitive edge.</p>
                      <p>Discovering successful solutions to really difficult problems isn’t easy. We know that not all of our companies will succeed, and even the ones
                        that do may not achieve the precise goals they set out for themselves. However, companies that strive to change the world can still be very
                        valuable, even if they only realize a fraction of their vision. We may not invest in the next Google, and that’s OK. We’re confident that by
                        adhering to our core principles, it will provide an outstanding return to our investors.</p>
                  </div>
                </div>
              </div>

            </div>

            <div id='panel-hindsight'>

              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>Hindsight Is Always 20/20</h2>
                  </div>
                </div>
              </div>

              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap'>
                    <p className='with-initial'>Some of the most promising and ambitious companies fail. Many do, in fact. The venture industry is replete with ambitious
                      failures, even those that were well managed. CO Corp. built a tablet computer with state-of-the-art technology in 1987. Unfortunately, the market was
                      not yet ready to embrace that technology, and it would take another 20 years for the market to accept such a large change. However, the company left
                      behind alumni who helped build the next generation of disruptive companies, including Bill Campbell who later became the Chairman of Intuit, Omid
                      Kordestani who became VP of Global Business at Google and Stratton Sclavos who acted as CEO of Verisign upon its IPO.</p>
                    <p>Failure is an important part of venture; we should be failing often, but for the right reasons. Venture is meant to be tolerant of failure. But failing
                      because we overpaid for a Backstretch Bandwagon company isn’t laudable. That’s a trade that doesn’t work. CO Corp. was an ambitious innovation that
                      didn’t work out, but it was an investment well worth making."</p>
                  </div>
                </div>
              </div>

            </div>

            <div id='panel-whatwedo'>

              <div className='layer-header'>
                <div className='grid'>
                  <div className='row'>
                    <h2 className='col-m-4 col-t-4 off-t-2 col-d-6 off-d-3'>What We Do</h2>
                  </div>
                </div>
              </div>

              <div className='layer-copy grid'>
                <div className='row'>
                  <div className='white-wrap' id='panel-whatwedo-white-wrap'>
                    <p className='with-initial'>We make investments. We’re no different in that sense than a lot of other firms. But how we make them and who we are is what sets us apart. There are always firms willing to fund another Double Stuf Oreo or back the horse leading down the backstretch. Let’s face it: these sorts of investments seem far less risky. However, we believe that the people who fund really ambitious ideas, who back companies that seem “crazy,” and who invest in teams that show an obsession in making something better are the people who end up actually changing how we live.</p>
                    <p>Let other companies build the “me too” products. We believe in our vision and are in search of the next dream. And we want you to be a part of it.</p>
                    <p className='closing'>R7.vc</p>
                  </div>
                </div>
              </div>

              <div className='layer-nebula'>
                <div className='nebula-wrap'>
                  <div className='nebula'></div>
                  <canvas id='flyby_anim' className='nebula-canvas' ref={this.references.flyby_anim} width='1920' height='1080'/>
                </div>
              </div>

            </div>

          </div> {/* /panels */}


          <div id='screen-end' className='screen'>
            <div className='layer-stars'></div>
            <div className='layer-logo manifesto_link'><ArrowLink to='/'>Next Chapter - Companies</ArrowLink></div>
          </div>

        </div>
      </div>
    )
  }
}

export default Manifesto
