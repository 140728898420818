import React, { useEffect, useState } from 'react';
import { motion, usePresence } from "framer-motion"
import { Waypoint } from 'react-waypoint';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import SanityHelmet from '../common/SanityHelmet';
import sanityProvider from '../../libs/sanity';
import ReponsiveSanityImage from '../common/ResponsiveImage';
import { urlBuilder } from '../../libs/routes';
import Revealer from '../common/Revealer'

function TeamSection(props) {

  const section = props.section;

  const [revealed, setRevealed] = useState(false);

  const sectionClasses = classNames('row', 'page-team__section', {
    revealed: revealed
  })

  return(
    <div className={sectionClasses}>
      <Waypoint onEnter={() => setRevealed(true)} onLeave={(e) => { if(e.currentPosition === 'below') {setRevealed(false);} }}>
        <h2 className='col-m-4'>
          <Revealer>{section.name}</Revealer>
          <hr className='thin-line'/>
        </h2>
      </Waypoint>
      <div className='col-m-4 col-t-6 col-d-9'>
        <ul className='page-team__member-list'>
        { section.members.map( (member, j) => {
          return(
            <li key={j} className='page-team__member'>
              <Link to={urlBuilder(member)}>
                <div className='page-team__member-img'>
                  <ReponsiveSanityImage asset={member.picture?.asset} maxWidth={300}/>
                </div>
                <span>{member.name}</span>
              </Link>
            </li>
          )
        })}
        </ul>
      </div>
    </div>
  )
}

function Team(props) {
  const [isPresent, safeToRemove] = usePresence();

  const teamData = sanityProvider.findFirstOfType('teamLanding');

  /* Tmp overrides to populate */
  // const newSec = teamData.sections[0];
  // newSec.members = newSec.members.concat(newSec.members,newSec.members,newSec.members,newSec.members);
  // const newSecs = [newSec, newSec];

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key='teamLanding'>
      <section className='page-team grid'>

        <SanityHelmet documentTags={teamData.metaTags}/>

        <div className='row'>
          <h1 className='col-m-4 col-t-6 off-t-2 col-d-9 off-d-3'>{teamData.header}</h1>
        </div>

        { teamData.sections.map( (section, i) => {
          // newSecs.map( (section, i) => {
          return(
            <TeamSection section={section}  key={i}/>

          )
        })}

      </section>
    </motion.div>
  )
}

export default Team;
