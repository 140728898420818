import React from 'react';
import sanityProvider from '../../libs/sanity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, matchPath } from "react-router";
import BlockContent from '@sanity/block-content-to-react'
import classNames from 'classnames/bind';

function FooterCommon(props) {

  const year = new Date().getFullYear()

  return (
    <div className='footer__common'>
      <ul>
        <li><a href='mailto:hello@r7.vc'>Contact</a></li>
        {/* <li><a href='https://invest.r7partners.com/' target="_blank" rel="noopener noreferrer">LP Login</a></li> */}
      </ul>
      <p className='copyright'>&copy; {year} <FontAwesomeIcon icon="circle" /> r7 partners <FontAwesomeIcon icon="circle" /> Chicago, New York </p>
    </div>
  )
}

function Footer(props) {
  // const footerData = sanityProvider.query('[_type=footer]');
  const footerData = sanityProvider.findFirstOfType('globalConf').footer;

  let studyModifierClasses = [];
  const studyContext =  matchPath(props.location.pathname, { path: '/companies/:id', exact: true } );
  if(studyContext) {
    const { id } = studyContext.params;
    // console.log("FOOTER", studyContext);
    const study = sanityProvider.findCaseStudyBySlug(id);
    const layoutType = study.layoutType || 'vertical';
    if(study) {
      studyModifierClasses = [
        'footer--study', `footer--study--${layoutType}`
      ]
    }
  }

  const footerClasses = classNames(
    'grid', studyModifierClasses
  );

  return (
    <footer className={footerClasses}>
      <div className='row'>
        <div className='footer__brand'>
          <span>R7 Partners</span>
        </div>
        <div className='footer__copy'>
          <BlockContent blocks={footerData.text}/>
          <FooterCommon/>
        </div>
      </div>
    </footer>
  );
}

const withRouterFooter = withRouter(Footer);
export { withRouterFooter as Footer, FooterCommon }