import ResponsiveAnimations from './responsiveAnimations'
import { TimelineMax } from "gsap";

class DesktopAnimations extends ResponsiveAnimations {

  createAnimations() {
    return this.createPageTimeline([
      this.sustainable_pabel(),
    ])
  }

  sustainable_pabel() {


    this.tagForClear([ ".scroller-panel", ".scroller-panel li" ])

    const listEntries = document.querySelectorAll('.scroller-panel li')
    // const textEntries = document.querySelectorAll('.scroller-panel li').length
    const entryCount = listEntries.length
    // const greyedOut = "rgba(180, 180, 180, 1)"

    /* Pinned background */
    const pin = new TimelineMax( this.timelineParams(".scroller-panel .image-wrapper", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
        anticipatePin: 1,
        trigger:    ".scroller-panel",
        start:      "top top",
        end:        "bottom bottom",
        duration:   entryCount + 1,
        onUpdate: self => {
          const t = Math.ceil(self.progress * (entryCount-1))
          listEntries.forEach( (entry,i) => {
            if(i===t) {
              entry.classList.add('active')
            }
            else {
              entry.classList.remove('active')
            }
          })
        }
      }
    }) )

    // for(let i = 1; i <= textEntries; i++) {
    //   pin.fromTo(`.scroller-panel li:nth-child(${i})`, { color: greyedOut }, { duration: 0.5, color: "rgba(255,255,255,1)" }, i)
    //     .to(`.scroller-panel li:nth-child(${i})`, { color: greyedOut, duration: 0.5}, i + 1.2)
    // }

    return [pin]
  }
}

export default DesktopAnimations