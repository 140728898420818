import React, { useEffect, useState } from 'react';
import { motion, usePresence } from "framer-motion"
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SanityHelmet from '../common/SanityHelmet';
import sanityProvider from '../../libs/sanity';
import ReponsiveSanityImage from '../common/ResponsiveImage';
import { urlBuilder } from '../../libs/routes';
import { formatDate } from '../../libs/date'

import readingTime from 'reading-time';
// import concat from 'lodash/concat';

function UpdateCard(props) {

  const isFeatured = props.featured;
  const update = props.update;

  const updateClasses = classNames('', 'page-updates__update col-m-4', {
    'page-updates__update--featured': isFeatured
  });

  /* Get text for reading time calculation */
  const text = update.blocks?.map( (block) => {
    return block.copy?.map( (copyblock) => {
      return copyblock.children.map( (child) => {
        return child.text;
      }).join("\n");
    }).join("\n");
  }).join("\n") || '';

  const readTime = readingTime(text);
  const readTimeMinutes = Math.max(1, Math.round(readTime.minutes))

  const external = update.external

  const LinkOrA = (props) => {
    const { link, ...rest } = props
    return external
      ? <a href={link} {...rest} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      :
        <Link to={link} {...rest}>
          {props.children}
        </Link>
  }

  const overrideOgTags = {
    title: "Updates",
    ogTitle: "Updates"
  }

  return (
    <div className={updateClasses}>
      <SanityHelmet documentTags={overrideOgTags}/>
      <LinkOrA link={external ? update.link : urlBuilder(update)} className='link--no-hover page-updates__update-wrapper'>
        <div className='page-updates__update-card'>
          <div className='page-updates__update-card__img'>
            <ReponsiveSanityImage asset={update.picture?.asset} maxWidth={700} squareCrop={!isFeatured}/>
          </div>
          <div className='page-updates__update-card__meta'>
            <span>{formatDate(update.publishedAt, true)}</span>
            { update.external !== true &&
              <>
                <FontAwesomeIcon icon="circle" size="xs"/>
                <span>{readTimeMinutes} min read</span>
              </>
            }
          </div>
          <h2>{update.title}</h2>
          { update.summary &&
            <p>{update.summary}</p>
          }
          { isFeatured &&
            <span className='link-more'>Read More</span>
          }
        </div>
      </LinkOrA>
    </div>
    )
}

function Updates(props) {
  const [isPresent, safeToRemove] = usePresence();
  const history = useHistory();

  const updatesFirstPage = sanityProvider.findAllByTypes('update');

  /* Let current page and current list of updates be stateful;
     Get the defaut based on history state for restoring page data upon back navigation.
  */
  const [paginationData, setPaginationData] = useState({
    page: history?.location?.state?.page || 0,
    updates: history?.location?.state?.updates || updatesFirstPage
  });

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  /* Load more callback */
  const loadMore = async (e) => {
    const nextPage = page + 1;
    /* Get next page of updates */
    const nextPageUpdates = await sanityProvider.getUpdatesPage(nextPage);
    /* Rebuild entire list */
    const allUpdates = [].concat(updates, nextPageUpdates)

    /* Remember scroll position */
    const pastScrollY = window.scrollY;

    /* Save state in history (should be enough space for json content) */
    const newState = {
      page: nextPage,
      updates: allUpdates,
    };
    history.replace({ state: newState });

    /* And replace current state for component update */
    setPaginationData(newState);

    /* And scroll back to where we were to avoid adding content above the scroll position */
    window.scrollTo(0, pastScrollY);
  }

  const { updates, page } = paginationData;

  // let { updates, page } = paginationData;
  // updates = concat(updates, updates, updates);

  const featuredUpdate = updates[0];
  const remainingUpdates = updates.slice(1);

  return (
    <motion.div transition={{ duration: 1 }} animate={{ opacity: 1 }} initial={{ opacity: 1 }} key='updates'>
      <section className='page-updates grid'>
        <div className='row'>
          <h1 className='col-m-4 col-t-6 off-t-2 col-d-9 off-d-3'>Updates</h1>
        </div>

        <div className='page-updates__updates row'>
          <div className='page-updates__update__header col-m-4'>featured update</div>
          <UpdateCard update={featuredUpdate} featured={true}/>
          {remainingUpdates.map((update, i) =>
            <UpdateCard key={i} update={update}/>
          )}
        </div>

        { page < sanityProvider.maxUpdatePage &&
          <div className='page-updates__load-more row'>
            <div className='col-m-4 col-t-4 off-t-4 col-d-3 off-d-3'>
              <button onClick={loadMore}>Load More</button>
            </div>
          </div>
        }

      </section>
    </motion.div>
  )
}

export default Updates;