import React from 'react';

function Revealer(props) {
  return (
    <span className='revealer'>
      <span className='revealer_inner'>
        {props.children}
      </span>
    </span>
  )
}

export default Revealer;