import ResponsiveAnimations from './responsiveAnimations'
import gsap, { TimelineMax } from "gsap";

class DesktopAnimations extends ResponsiveAnimations {

  /* This is based on the intro1 parameter to animate all the way to r7 logo at full opacity */
  scrollAmountOnLanding = window.innerHeight * 3 * 2.5 / 4.5;

  createAnimations() {
    return this.createPageTimeline([
      this.intro1(),
      this.intro2(),
      this.intro3(),
      this.panel_pia(),
      this.panel_moon(),
      this.panel_dunes(),
      this.panel_sand(),
      this.panel_risky(),
      this.panel_change(),
      this.panel_lookfor(),
      this.panel_scotty(),
      this.panel_bond(),
      this.panel_eclipse(),
      this.panel_verb(),
      this.panel_hindsight(),
      this.panel_whatwedo(),
      this.end_screen()
    ])
  }

  intro1() {

    this.tagForClear([  "#intro .bg-starfield", "#intro .logo", "#intro .iss" ])

    return new TimelineMax( this.timelineParams('#intro .part-spacelogo', {
      scrollTrigger: {
        scrub: true,
        end: "bottom bottom"
      }
    }))
      .fromTo(  "#intro .bg-starfield",  { opacity: 0.01, scale: 1.116 },  { duration: 2, opacity: 0.25, scale: 1 },             0 )
      .fromTo(  "#intro .logo",         { opacity: 0.01, scale: 0.33 },   { duration: 2.25, opacity: 1, scale: 1 },             0 )
      .fromTo(  "#intro .iss",          { opacity: 0.01 },                { duration: 1.75, opacity: 1 },                       0.7 )
      .fromTo(  "#intro .iss",          { x: '-30%', y: '75vh' },         { duration: 2.50, y: '40vh', ease: "power1.inOut" },  0 )

      .addLabel("intro1-out", 3)
      .to(      "#intro .iss",          { duration: 2, y:'-50vh', ease: "power1.inOut" },     "intro1-out" )
      .to(      "#intro .iss",          { duration: 2, opacity:0, ease: "power1.inOut" },     "intro1-out" )
      .to(      "#intro .logo",         { duration: 0.8, opacity:0, ease: "power1.inOut" },   "intro1-out")
      .to(      "#intro .bg-starfield",  { duration: 1, opacity:0, ease: "power1.inOut" },     "intro1-out")
  }

  intro2() {

    this.tagForClear([  "#intro .moon", "#intro .bg-starfield", "#intro .solar-panel",
                        "#intro .copy1", "#intro .copy2", "#intro .copy3" ])

    return new TimelineMax( this.timelineParams('#intro .part-moonpanel', {
      scrollTrigger: {
        scrub: true,
        end: "bottom bottom"
      }
    }))
      .addLabel("intro2")
      .fromTo(  "#intro .moon", { y: '100%' },    { duration: 3.5, y:0, ease: "power1.inOut" },           "intro2+=0.4" )
      .fromTo(  "#intro .moon", { opacity: 0 },   { opacity:1, duration: 3.5, ease: "power1.inOut" },     "intro2" )
      .fromTo(  "#intro .moon", { scale: 0.28 },  { duration: 3, scale:0.99, ease: "power1.in" },         "intro2" )
      .to(      "#intro .bg-starfield",            { duration: 0.5, opacity:0.25, ease: "power1.inOut" },  "intro2+=2.5" )

      .addLabel("intro2_2")
      .to(      "#intro .moon",                   { rotate: '-45deg', duration: 1, ease: "power1.inOut" },  "intro2_2" )

      .addLabel("intro2_3")
      .to(      "#intro .moon",                                                               { rotate: '0deg', duration: 4, ease: "power1.inOut" },        "intro2_3" )
      .fromTo(  "#intro .solar-panel",       { x:0, y: '100%' },                               { y:'0%', duration: 3, ease: "power1.inOut" },                "intro2_3+=0.2" )
      .fromTo(  "#intro .copy1",      { visibility:'visible', opacity:0, scale: 0.7 },  { opacity:1, scale: 1, duration: 3, ease: "power1.inOut" },   "intro2_3+=1" )
      .fromTo(  [ "#intro .copy2",
                  "#intro .copy3" ],  { visibility:'visible', opacity:0, scale: 0.7 },  { opacity:1, scale: 1, duration: 2.5, ease: "power1.inOut" }, "intro2_3+=0.3" )

      .addLabel("intro2-out", "+=1")
      .to(  "#intro .moon",           { x: '-100%', duration: 3.5, ease: "power1.inOut" },  "intro2-out" )
      .to(  "#intro .solar-panel",     { y: '-100%', duration: 3.5, ease: "power1.inOut" },  "intro2-out" )
      .to(  [ "#intro .bg-starfield",
              "#intro .copy1",
              "#intro .copy2",
              "#intro .copy3" ], { opacity: 0, duration: 1, ease: "power1.inOut" },   "intro2-out" )

  }

  intro3() {

    this.tagForClear([  "#intro .layer-starzoom", "#intro .layer-copy", "#intro .part-copyout",
                        "#starfieldZoom", "#intro .copy_out1", "#intro .copy_out2" ])

    /* Star Zoom */
    const starZoomFrameRef = {
      currentFrame: 0
    }
    const fixedLayers = ["#intro .layer-starzoom", "#intro .layer-copy"]

    const starZoom = new TimelineMax( this.timelineParams("#intro .part-copyout", {
      scrollTrigger: {
        start:    "top bottom",
        end:      "bottom top-=100%",
        scrub:    true,
        duration: 3
      }
    }) )
      .addLabel("starzoom")
      .to( starZoomFrameRef,
        {
          currentFrame: this.dataForAnimations.starZoomData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.starZoomData.render(starZoomFrameRef.currentFrame)
          },
          snap: "currentFrame",
          duration: 3
        }, "starzoom"
      )
      /* Next line fixes an issue with a leftover transform when switching breakpoints */
      .set(     "#intro .part-copyout",                               { clearProps: "transform" },                                  "starzoom" )
      /* Make sure layers are pinned */
      .set(     fixedLayers,                                          { visibility: 'visible', position: 'fixed' },                 "starzoom" )
      .fromTo(  "#starfieldZoom",  { opacity:0 },            { opacity:0.3, duration:0.3 },                                "starzoom" )
      .fromTo(  "#intro .copy_out1",     { opacity:0, scale:0.7 }, { opacity:1, scale:1, duration:2.25, ease: "power1.inOut" },  "starzoom+=0.25" )
      .fromTo(  "#intro .copy_out2",     { opacity:0, scale:0.7 }, { opacity:1, scale:1, duration:2.25, ease: "power1.inOut" },  "starzoom+=0.3" )

     /* This is to *unpin* the starzoom and copy */
     const unpin = new TimelineMax( this.timelineParams("#intro .part-copyout", {
      scrollTrigger: {
        start:    "bottom bottom",
        end:      "bottom top",
        duration: 1,
      }
    }) )
      .set( fixedLayers, { position: 'absolute', top: 'auto', bottom: 0 } )
      .to(  fixedLayers, { opacity: 1 } ) /* This timeline won't get executed with an actual animation directive */

    return [starZoom, unpin]
  }

  panel_pia() {

    this.tagForClear([ "#intro", "#panel-pia .pia", "#panel-pia .layer-pia" ])

    /* Pinned background */
    const pin = new TimelineMax( this.timelineParams("#panel-pia .layer-pia", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-pia",
        end:        "bottom bottom",
        duration:   4
      }
    }) )
      /* Set to change background attachment prop about 1/2 way through pin to avoid any jump in animation */
      .addLabel("pin")
      .set(     '#intro',         { visibility: 'hidden'} )
      .fromTo(  "#panel-pia .pia",  { scale:1 },                            { scale: 1.25, duration: 4 } , "pin" )
      .set(     "#panel-pia .pia",  { 'background-attachment': 'scroll' } )

    window.pia_test = pin

    return [pin]
  }

  panel_moon() {

    this.tagForClear([ "#panel-moonphases .layer-moonstars", "#panel-pia", "#panel-moonphases .starfield" ])

    /* Pinned background */
    const pin = new TimelineMax( this.timelineParams("#panel-moonphases .layer-moonstars", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-moonphases",
        end:        "bottom bottom",
      }
    }) )
      .addLabel("pin")
      .set( '#panel-pia', { visibility: 'hidden'} )

    /* Moon Phases */
    const moonPhaseFrameRef = {
      currentFrame: 0
    }

    const moonPhases = new TimelineMax( this.timelineParams("#panel-moonphases", {
      scrollTrigger: {
        start:  "top top",
        end:    "bottom top",
        scrub:  true,
      }
    }) )
      .set( "#panel-moonphases .starfield",  { opacity: 0.25, scale: 1.116 } )
      .to(
        moonPhaseFrameRef,
        {
          currentFrame: this.dataForAnimations.moonPhasesData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.moonPhasesData.render(moonPhaseFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }
      )

      return [pin, moonPhases]
  }

  panel_dunes() {

    this.tagForClear([ "#panel-dunes .layer-dunes", "#panel-dunes .dunes" ])

    /* Pinned background */
    const pin = new TimelineMax( this.timelineParams("#panel-dunes .layer-dunes", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-dunes",
        end:        "bottom bottom",
        duration:   4
      }
    }) )
      /* Set to change background attachment prop about 1/2 way through pin to avoid any jump in animation */
      .addLabel("pin")
      .fromTo(  "#panel-dunes .dunes", { backgroundPosition: '50% 0%' },      { backgroundPosition: '50% 40%', duration: 4, ease: "power1.in" } , "pin" )
      .set(     "#panel-dunes .dunes", { 'background-attachment': 'scroll' } )

    return [pin]
  }

  panel_sand() {

    this.tagForClear([ "#panel-sand .layer-sand", "#panel-sand .sand" ])

    /* Pinned background */
    const pin = new TimelineMax( this.timelineParams("#panel-sand .layer-sand", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
        endTrigger: "#panel-sand",
        end:        "bottom bottom",
        duration:   4
      }
    }) )
      /* Set to change background attachment prop about 1/2 way through pin to avoid any jump in animation */
      .addLabel("pin")
      .fromTo(  "#panel-sand .sand", { scale: 2 },                           { scale: 3, duration: 4, ease: "power1.in" } , "pin" )
      .set(     "#panel-sand .sand", { 'background-attachment': 'scroll' } )

    return [pin]
  }

  panel_risky() {

    this.tagForClear([  "#panel-risky .layer-header", "#panel-risky .layer-copy", "#panel-risky .bloodcell",
                        "#panel-risky .bloodcell-mask-rot", "#panel-risky .pluto" ])

    const pinHeader = new TimelineMax( this.timelineParams("#panel-risky .layer-header", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: true,
      }
    }) )
      .set(     "#panel-risky",               { visibility: 'visible' } )
      .fromTo(  "#panel-risky .layer-header", { opacity: 0, scale: 0.85 }, { opacity: 1, scale: 1 } )

    const pinCopy = new TimelineMax( this.timelineParams("#panel-risky .layer-copy", {
        scrollTrigger: {
          pin:        true,
          pinSpacing: true,
        }
      }) )
      .addLabel("pin")
      .set( "#panel-risky .bloodcell, #panel-risky .bloodcell-mask-rot",  { opacity: 1 } )
      .to(  "#panel-risky .pluto",                                        { rotate: 90, ease: "none" },   "pin" )
      .to(  "#panel-risky .bloodcell-mask-rot",                           { rotate: -270, ease: "none" }, "pin" )
      .set( "#panel-risky .bloodcell, #panel-risky .bloodcell-mask-rot",  { opacity: 0 } )

    const plutoIn = new TimelineMax( this.timelineParams("#panel-risky .layer-copy", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "top 100px"
      }
    }) )
      .set(     "#panel-risky .pluto", { opacity: 1} )
      .fromTo(  "#panel-risky .pluto", { top: '100%', rotate: 0 }, { top: '-325px', rotate: 45, ease: "none" } )

    const plutoOut  = new TimelineMax( this.timelineParams("#panel-risky .layer-copy", {
        scrollTrigger: {
          start:  "bottom 99%",
          end:    "bottom 50%"
        }
      }) )
        .to( "#panel-risky .pluto", { top: '-100%' } )

    return [pinHeader, pinCopy, plutoIn, plutoOut]
  }

  panel_change() {

    this.tagForClear([  "#panel-moonphases", "#panel-change", "#panel-change .layer-header",
                        "#panel-change .layer-mercury", "#panel-change .layer-starfield" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-change", {
      scrollTrigger: {
        start:    "top bottom",
        end:      "top top",
        duration: 2
      }
    }) )
      .set(     "#panel-moonphases",           { visibility: 'hidden' },                              "0" )
      .set(     "#panel-change",               { visibility: 'visible' },                             "0" )
      .fromTo(  "#panel-change .layer-header", { opacity: 0, scale: 0.85 }, { opacity: 1, scale: 1 }, "1" )
      .set(     "#panel-change .layer-header", { position: 'absolute' } )

    const mercuryFrameRef = { currentFrame: 0 }
    const mercuryAnim = new TimelineMax( this.timelineParams("#panel-change .layer-copy", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "bottom top",
        scrub:  true
      }
    }) )
      .addLabel( "mercury_anim")
      .fromTo(  "#panel-change .layer-mercury",   { top: "100%" },            { top: "-100%", ease: "none" }, "mercury_anim" )
      .set(     "#panel-change .layer-starfield", { visibility: 'visible' },                                  "mercury_anim" )
      .fromTo(  "#panel-change .layer-starfield", { top: "0" },               { top: "-100%", ease: "none" }, "mercury_anim" )
      .to(
        mercuryFrameRef,
        {
          currentFrame: this.dataForAnimations.mercuryAnimData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.mercuryAnimData.render(mercuryFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }, "mercury_anim"
      )

    return [headerInOut, mercuryAnim]
  }

  panel_lookfor() {

    this.tagForClear([  "#panel-risky", "#panel-change", "#panel-lookfor",
                        "#panel-lookfor .layer-solstice", "#panel-lookfor .layer-header" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-lookfor", {
      duration: 4,
      scrollTrigger: {
        start:      "top top",
        endTrigger: "#panel-lookfor .layer-copy",
        end:        "top bottom",
      }
    }) )
      .set(     "#panel-risky",                   { visibility: 'hidden' },                                                               "0" )
      .set(     "#panel-change",                  { visibility: 'hidden' },                                                               "0" )
      .set(     "#panel-lookfor",                 { visibility: 'visible' },                                                              "0" )
      .fromTo(  "#panel-lookfor .layer-solstice", { top: '100vh', left: '-100vh' }, { top:0, left:0, duration:4, ease: "power1.inOut" },  "0" )
      .fromTo(  "#panel-lookfor .layer-header",   { opacity: 0 },                   { opacity: 1 },                                       "-=1" )

    const headerPushOut = new TimelineMax( this.timelineParams("#panel-lookfor .layer-copy", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "top top",
      }
    }) )
      .to( "#panel-lookfor .layer-solstice, #panel-lookfor .layer-header", { top: '-100%', ease: "none" } )

    return [headerInOut, headerPushOut]
  }

  panel_scotty() {

    this.tagForClear([ "#panel-scotty .layer-starfield", "#panel-scotty .starfield", "#panel-bond" ])

    const pinBg = new TimelineMax( this.timelineParams("#panel-scotty .layer-starfield", {
      scrollTrigger: {
        pin:        true,
        pinSpacing: false,
        endTrigger: "#panel-scotty .layer-copy-3",
        end:        "bottom top",
      }
    }) )
      .fromTo( "#panel-scotty .starfield", { scale: 1 }, { scale: 1.7, ease: "none" } )
      /* Set visiblity for next panel */
      .set(     "#panel-bond",    { visibility: 'visible' } )

    return [pinBg]
  }

  panel_bond() {
    this.tagForClear([ "#panel-bond .rocket", "#panel-bond .startrail" ])

    const pinImages = new TimelineMax( this.timelineParams("#panel-bond .layer-copy", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "bottom top",
      }
    }) )
      .fromTo(  "#panel-bond .rocket",    { backgroundPosition: 'left 49vw top -40vh' },  { backgroundPosition: 'left 49vw top 0vh', ease: "none" },  "0" )
      .fromTo(  "#panel-bond .startrail", { backgroundPosition: 'right -10vw top 0' },    { backgroundPosition: 'right 0vw top 0', ease: "none" },    "0" )

    return [pinImages]
  }

  panel_eclipse() {

    this.tagForClear([  "#panel-eclipse .layer-eclipse", "#panel-eclipse .eclipse",
                        "#panel-eclipse .layer-copy-1" ])

    const prePin = new TimelineMax( this.timelineParams("#panel-eclipse .layer-copy-1", {
      scrollTrigger: {
        start:  "top bottom",
        end:    "top top",
      }
    }) )
      .set(     "#panel-eclipse .layer-eclipse",  { visibility: 'visible' },                          "0" )
      .set(     "#panel-eclipse .eclipse",        { scale:0.5 },                                      "0" )
      .fromTo(  "#panel-eclipse .eclipse",        { rotate: 0, y:'-15%' }, { rotate: 45, y: '15%' },  "0" )

    const pin = new TimelineMax( this.timelineParams("#panel-eclipse .layer-copy-1", {
      duration: 10,
      scrollTrigger: {
        pin:        "#panel-eclipse .layer-eclipse",
        pinSpacing: false,
        start:      "top top",
        endTrigger: "#panel-eclipse .layer-copy-2",
        end:        "bottom bottom",
      }
    }) )
      /* This next line is necessary for maintaining proper state when scrolling back up */
      .set(       "#panel-eclipse .eclipse", { scale: 0.5, visibility: 'visible' },                         "0" )
      .fromTo(    "#panel-eclipse .eclipse", { scale: 0.5 },                    { scale: 1, duration: 7 },  "0" )
      .to(        "#panel-eclipse .eclipse", { scale: .7, duration: 2.5 } )
      .to(        "#panel-eclipse .eclipse", { scale: 0, duration: 0.5 } )
      .addLabel(  "eclispe-disappear" )
      .set(       "#panel-eclipse .eclipse", { scale:0, visibility: 'hidden' },                             "eclispe-disappear" )

    return [prePin, pin]
  }

  panel_verb() {

    this.tagForClear([ "#panel-verb .layer-header", "#panel-eclipse .layer-copy-3" ])

    /* Pin the current panel */
    const pinVerbPanel = new TimelineMax( this.timelineParams("#panel-verb .layer-header", {
      scrollTrigger: {
        pin:        "#panel-verb",
        pinSpacing: true,
        start:      "center center",
        end:        "center top",
      }
    }) )
      .fromTo( "#panel-verb .layer-header", { opacity: 0 }, { opacity: 1, ease: "none" } )

    /* Pin the previous panel copy so that we don't see then end of the previous copy scroll up during pinning */
    const pinEclipsePanel = new TimelineMax( this.timelineParams("#panel-verb .layer-header", {
      scrollTrigger: {
        pin:        "#panel-eclipse .layer-copy-3",
        pinSpacing: false,
        start:      "center center",
        end:        "center top",
      }
    }) )

    return [pinVerbPanel, pinEclipsePanel]
  }

  panel_hindsight() {

    this.tagForClear([ "#panel-hindsight", "#panel-hindsight .layer-header" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-hindsight", {
      scrollTrigger: {
        start:      "top center",
        end:        "top top",
      }
    }) )
      .set(     "#panel-hindsight",               { visibility: 'visible'}                                    , "0")
      .fromTo(  "#panel-hindsight .layer-header", { opacity: 0 },             { opacity: 1, ease: "none" } )
      .set(     "#panel-hindsight .layer-header", { position: 'absolute' } )

    return [headerInOut]
  }

  panel_whatwedo() {

    this.tagForClear([ "#panel-whatwedo", "#panel-whatwedo .layer-header", "#panel-whatwedo .layer-copy",
                      "#panel-whatwedo .layer-copy .white-wrap", "#panel-whatwedo .layer-nebula", "#panel-whatwedo .white-wrap" ])

    const headerInOut = new TimelineMax( this.timelineParams("#panel-whatwedo", {
      scrollTrigger: {
        start:      "top center",
        end:        "top top",
      }
    }) )
      .set(     "#panel-whatwedo",               { visibility: 'visible'}                                    , "0")
      .fromTo(  "#panel-whatwedo .layer-header", { opacity: 0 },             { opacity: 1, ease: "none" } )

    const collapse = new TimelineMax( this.timelineParams("#panel-whatwedo .layer-copy", {
      scrollTrigger: {
        start:      "top bottom",
        end:        "top top",
      }
    }) )
      .fromTo(  "#panel-whatwedo .layer-header",            { height: '100vh' } ,                     { height: '20vh', ease: "power1.out" }, "0" )
      .to(      "#panel-whatwedo .layer-copy .white-wrap",  { y: '20vh', ease: "power1.out" }, "0" )

    /* We pin based on layer-copy that is not affected by the above 20vh displacement */
    const pinCopy = new TimelineMax( this.timelineParams("#panel-whatwedo .layer-copy", {
      scrollTrigger: {
        /* We pin the row div to not mess with the layer copy itself (so we can keep it as a scroll track reference) */
        pin:        "#panel-whatwedo .layer-copy .row",
        pinSpacing: false,
        endTrigger: "#panel-whatwedo",
        end:        "bottom bottom",
        /* And during the pin, we slowly scroll the header and the white-wrapped content to offset the 20vh + pin displacement to
           bring the bottom of the white-wrap in line with the bottom of the panel-whatwedo div */
        onUpdate: ({progress}) => {
          const whitewrap_h = document.getElementById('panel-whatwedo-white-wrap').offsetHeight;
          const window_h = window.innerHeight;
          const delta_h = whitewrap_h - window_h + window_h * .2;
          if(delta_h > 0) {
            const top_offset = - delta_h * progress;
            gsap.set("#panel-whatwedo .layer-header", { top: top_offset } )
            gsap.set("#panel-whatwedo .white-wrap", { top: top_offset } )
          }
        }
      }
    }) )

    const flybyFrameRef = { currentFrame: 0 }
    const nebulaAnim = new TimelineMax( this.timelineParams("#panel-whatwedo .layer-copy", {
      scrollTrigger: {
        start:      "top top",
        endTrigger: "#panel-whatwedo",
        end:        "bottom bottom",
      }
    }) )
      .to(  "#panel-whatwedo .layer-nebula", { top: '0vh', ease: "power1.out" }, "0" )
      .to(
        flybyFrameRef,
        {
          currentFrame: this.dataForAnimations.flyByAnimData.frameCount,
          onUpdate: () => {
            this.dataForAnimations.flyByAnimData.render(flybyFrameRef.currentFrame)
          },
          snap: "currentFrame",
        }, "0"
      )

    /* Artificially scroll nebula and header out of view at the same speed as the natural scroll */
    const nebulaAndHeaderOut = new TimelineMax( this.timelineParams("#panel-whatwedo", {
      scrollTrigger: {
        trigger:    "#panel-whatwedo",
        start:      "bottom bottom",
        end:        "bottom top",
      }
    }) )
      .to("#panel-whatwedo .layer-nebula", { top: '-100vh', ease: "none" }, "0" )
      .to("#panel-whatwedo .layer-header", { y: '-100vh', ease: "none" }, "0" )


    return [headerInOut, collapse, pinCopy, nebulaAnim, nebulaAndHeaderOut]
  }

  end_screen() {

    this.tagForClear([ "#screen-end .layer-logo" ])

    const r7out = new TimelineMax( this.timelineParams("#screen-end", {
      scrollTrigger: {
        start:      "top 50%",
        end:        "top top",
      }
    }) )
      .fromTo(  "#screen-end .layer-logo", { opacity: 0 }, { opacity: 1, ease: "none" } )

    return [r7out]
  }

}


export default DesktopAnimations