import React, { useEffect, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react'
import classNames from 'classnames/bind';
import { motion, usePresence } from "framer-motion"
import { Waypoint } from 'react-waypoint';
import { Helmet } from "react-helmet";

import sanityProvider from '../../libs/sanity';
import SanityHelmet from '../common/SanityHelmet';
import ResponsiveSanityImage from '../common/ResponsiveImage';
import AnimatedImage from '../common/AnimatedImage';
import Revealer from '../common/Revealer'

import { ReactComponent as IOSButton } from '../../assets/ios_button.svg';
import { ReactComponent as AndroidButton } from '../../assets/google_play.svg';

function colorForStudy(study) {
  return study.hexColor ? `#${study.hexColor}` : "#000"
}

function CaseStudyBlock(props) {


  const imageOddEvenTracker = props.imageOddEvenTracker
  const block = props.block

  /* Headline is optional */
  const headline = block.headline ?
    <div className='row page-study__block__headline'><h3>{block.headline}</h3></div>
    : null;

  const image = block.image ?
    <ResponsiveSanityImage asset={block.image.asset} maxWidth={1200} />
    : null;

  const copy = block.copy;

  /* Logic to set content layout types and alternate image position */
  const blockClass = classNames('grid', 'page-study__block', {
    'page-study__block--image_odd': imageOddEvenTracker % 2 === 1,
    'page-study__block--image_even': imageOddEvenTracker % 2 === 0,
    'page-study__block--indent-copy': block.indent_copy,
  })

  return (
    <div className={blockClass}>
      {block.headline && headline}
      <div className='row'>
        { copy &&
          <BlockContent serializers={sanityProvider.blockContentSerializers} renderContainerOnSingleChild={true} className='page-study__block__copy' blocks={block.copy}/>
        }
        { image &&
          <div className='page-study__block__image'>
            <AnimatedImage>{image}</AnimatedImage>
          </div>
        }
      </div>
    </div>
  )
}

function CaseStudyAppLinks(props) {
  const block = props.block
  return(
    <div className="grid page-study__block page-study__app_links">
      <div className="row">
        <div className="page-study__block__copy">
          { block.ios_url && <a href={block.ios_url} target="_blank" rel="noopener noreferrer"><IOSButton/></a>}
          { block.android_url && <a href={block.android_url} target="_blank" rel="noopener noreferrer"><AndroidButton/></a>}
        </div>
      </div>
    </div>
  )
}

function CaseStudy(props) {
  const [isPresent, safeToRemove] = usePresence();
  const [revealed, setRevealed] = useState(false);

  const study = props.study;

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  /* Will be used to track alternating image blocks */
  let imageOddEvenTracker = 1;

  const layoutType = props.study.layoutType || 'vertical';

  const pageClasses = classNames('page-study', `page-study--${layoutType}`);
  const heroMedia = study.heroMedia[0];
  const heroIsVideo = heroMedia._type === 'file'

  const studyColor = colorForStudy(study)

  const backgroundColorOverride = study.backgroundColor
  let lightText = false;
  if(backgroundColorOverride) {
    var r = parseInt(backgroundColorOverride.substr(0, 2), 16),
        g = parseInt(backgroundColorOverride.substr(2, 2), 16),
        b = parseInt(backgroundColorOverride.substr(4, 2), 16),
        brightness = 0.299 * r + 0.587 * g + 0.114 * b;
    if(brightness < 128) {
      lightText = true
    }
  }

  const bodyClasses = classNames( {
    'background-override': backgroundColorOverride,
    'text-light-override': backgroundColorOverride && lightText
  })

  /* Get a copy of the milestones */
  const milestones = study.milestones
    ? study.milestones.map((m) => {
        return {
          date: m.date,
          label: m.label
        }
      })
    : [];

  /* Add partnered in entry */
  if(study.partneredIn) {
    milestones.push({
      date: study.partneredIn,
      label: "Partnered"
    })
  }
  /* Sort by date */
  milestones.sort( (a,b) => {
    if(a.date === b.date)
      return a.label.localeCompare(b.label)
    return (new Date(a.date) <  new Date(b.date)) ? -1 : 1
  })

  const tag = study.sustainable ? "Sustainable" : study.tag

  return(

    <div>
      <motion.div intial={{y:0}} animate={{ y: "-100%" , transition: { delay:0.1, ease: "easeOut", duration: 0.5 }}} className="study_curtain" style={ { backgroundColor: studyColor } }/>
      <section className={pageClasses}>
        <SanityHelmet documentTags={study.metaTags || {}} fallbackTags={{title: study.name}}/>
        { backgroundColorOverride &&
          <Helmet>
            <body className={bodyClasses}/>
            <style>{`
              .background-override {
                background: #${backgroundColorOverride} !important;
              }
            `}</style>
          </Helmet>
        }

        <div className='grid'>
          <div className='row page-study__hero'>
            <h1 className='col-m-4 col-t-7 col-d-9 off-t-1 off-d-3' style={{color: studyColor}}>{study.name}</h1>
            <div className='col-m-4 col-t-8 col-d-12'>
              { heroIsVideo ?
                <video playsInline loop autoPlay muted src={heroMedia?.asset?.url}></video>
              :
                <ResponsiveSanityImage className="page-study__hero-img" asset={heroMedia?.asset} maxWidth={1440} alt={study.name}/>
              }
            </div>
          </div>

          <div className='row page-study__hero-caption'>
            <div className='page-study__hero-logo col-m-4 col-t-1 col-d-3'>
              <img src={ sanityProvider.getImageUrl( study.logo?.asset, { h:150, fit: 'max' } ) } alt=""/>
            </div>
            <h2 className='col-m-4 col-t-5 col-d-9'>{study.heroTagline}</h2>
          </div>

          <div className={ classNames('row', { revealed: revealed }) }>
            <Waypoint onEnter={() => setRevealed(true)} onLeave={(e) => { if(e.currentPosition === 'below') {setRevealed(false);} }}>
              <div className='page-study__attrs col-m-4 col-t-5 off-t-1 col-d-6 off-d-3'>
                <hr className='thin-line thin-line--top'/>
                <div className='page-study__attrs__col'>
                  <span>
                    <p><Revealer>Milestones</Revealer></p>
                      { milestones.map( (milestone, i) =>
                        <em className='milestone' key={i}><Revealer>{milestone.label} in { new Date(milestone.date + "T00:00:00-05:00").toLocaleDateString('en-en', { year: 'numeric' } ) }</Revealer></em>
                      ) }
                  </span>

                </div>
                <div className='page-study__attrs__col'>
                  <span>
                    <p><Revealer>INDUSTRY</Revealer></p>
                    <em><Revealer>{study.industry}</Revealer></em>
                  </span>
                  <span className='founder'>
                    <p><Revealer>FOUNDER{ study.multipleFounders && "S" }</Revealer></p>
                    <em><Revealer>{study.founder}</Revealer></em>
                  </span>
                  <span className='page-study__attrs__website'>
                    { tag && <p className='sustainable'>{tag}</p> }
                    <p><Revealer><a rel="noopener noreferrer" target="_blank" className="link--external--alt" href={study.website}>Website</a></Revealer></p>
                  </span>
                </div>
                <hr className='thin-line thin-line--bottom'/>
              </div>
            </Waypoint>
          </div>

        </div>

        { study.blocks?.map( (block,i) => {

          if(block.image) {
            imageOddEvenTracker++;
          }

          return(
            <React.Fragment key={i}>
              { (block._type === "caseStudyBlock") && <CaseStudyBlock imageOddEvenTracker={imageOddEvenTracker} block={block} /> }
              { (block._type === "caseStudyAppLinks") && <CaseStudyAppLinks block={block} /> }
            </React.Fragment>
          )

        })}

      </section>
    </div>
  )
}

export default CaseStudy;
export { colorForStudy }
