import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { motion, useAnimation, usePresence } from "framer-motion"
import BlockContent from '@sanity/block-content-to-react'
import classNames from 'classnames/bind';
import { throttle } from 'lodash';

import sanityProvider from '../../libs/sanity';
import { urlBuilder } from '../../libs/routes';
import SanityHelmet from '../common/SanityHelmet';
import { AnimatedArrow } from '../common/ArrowLink';
import ResponsiveSanityImage from '../common/ResponsiveImage';
import Revealer from '../common/Revealer'
import { colorForStudy } from './CaseStudy'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

function HomeListEntry(props) {

  const controls = useAnimation();

  const duration = 0.4,
        deltaY = 80;

  const variants = {
    "hidden": {
      opacity: 0,
      transition: { ease: "easeOut", duration: duration }
    },
    "enterFromLanding": {
      x: [-10,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    },
    "enterFromBelow": {
      x: [0,0],
      y:[deltaY,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    },
    "enterFromAbove": {
      x: [0,0],
      y:[-deltaY,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    },
    "disappearAbove": {
      y:[null, -deltaY],
      opacity: [1,0],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    },
    "disappearBelow": {
      y:[null, deltaY],
      opacity: [1,0],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    }
  }

  /* Trigger study animation in/out when waypoint is triggered */
  function wayPointTrigger(e, inOrOut) {
    let animation = "enterFromLanding";
    if(inOrOut === 'in') {
      switch(e.previousPosition) {
        case 'below':
          animation = 'enterFromBelow'; break;
        case 'above':
          animation = 'enterFromAbove'; break;
        default: break;
      }
    }
    if(inOrOut === 'out') {
      switch(e.currentPosition) {
        case 'below':
          animation = 'disappearBelow'; break;
        case 'above':
          animation = 'disappearAbove'; break;
        default: break;
      }
    }
    controls.start(animation);
  }

  /* Animating arrow on hover */
  const hoverControls = useAnimation();
  function linkArrowHover(e) {
    hoverControls.start( e.type === 'mouseover' ? 'enter' : 'exit');
  }


  const link = props.link

  /* Making sure the arrow is never wrapping after the last word */
  const words = props.entryDescription ? props.entryDescription.split(" ") : null;
  const tag = props.entryTag
  const lastWord = props.entryDescription ? words.pop() : null;

  const [revealed, setRevealed] = useState(false);

  const wrapperClasses = classNames('grid', {
    revealed: revealed
  })

  return (
    <Waypoint onEnter={(e) => {wayPointTrigger(e, 'in')}} onLeave={(e) => {wayPointTrigger(e, 'out')}} topOffset="125px" bottomOffset="50px">
      <div className={wrapperClasses}>
        <div className='grid'>
          <ConditionalWrapper
            condition={link}
            wrapper={children => <Link className='link--arrow' to={link} onMouseOver={linkArrowHover} onMouseOut={linkArrowHover} onClick={props.onClick}>{children}</Link>}
            >
            <motion.div className='row' animate={controls} initial="hidden" variants={variants}>
              <Waypoint onEnter={() => setRevealed(true)} onLeave={(e) => { if(e.currentPosition === 'below') {setRevealed(false);} }}>
                <div className="page-home__case-studies-copy col-m-4 col-t-2 col-d-3 ">
                  <hr className='thin-line'/>
                  <h2><Revealer>{props.entryName}</Revealer></h2>
                  {tag &&
                    <div className='page-home__case-studies-tag'>
                      <Revealer>
                        {tag}
                      </Revealer>
                    </div>
                  }
                  {words &&
                    <p><Revealer>
                      {words.join(" ")}{" "}
                      <span style={{whiteSpace: 'nowrap'}}>{lastWord}<AnimatedArrow controls={hoverControls} /></span>
                    </Revealer></p>
                  }
                </div>
              </Waypoint>
              <div className='page-home__case-studies-img col-m-4 col-t-6 col-d-9'>
                {props.entryContent}
              </div>
            </motion.div>
          </ConditionalWrapper>
        </div>
      </div>
    </Waypoint>
  )
}

function HomeCaseStudy(props) {
  const study = props.study;
  const studyUrl = urlBuilder(study);


  const entryName = study.name
  const entryDescription = study.homePageFeature.homePageTagline
  const entryTag = study.homePageFeature.homeTag

  const entryContent = <ResponsiveSanityImage className="page-study__hero-img" asset={study.homePageFeature.homePageImage.asset} maxWidth={1000} alt={study.name}/>

  return (
    <HomeListEntry onClick={props.onClick} entryName={entryName} entryDescription={entryDescription} entryContent={entryContent} entryTag={entryTag} link={studyUrl}/>
  )
}

function HomeOtherCompanyEntry(props) {

  const companyData = props.companyData

  const partneredInFragments = companyData.partneredIn.split("-")
  const year = partneredInFragments[0]

  const tag = companyData.sustainable ? "Sustainable" : companyData.tag

  /* Programmatically change layout based on height of description */
  const [tallDescription, setTallDescription] = useState(false)
  const ref = useRef(null)

  const checkDecriptionHeight = throttle( () => {
    if(ref.current) {
      setTallDescription(ref.current.clientHeight > 30)
    }
  }, 200)

  useEffect( () => {
    checkDecriptionHeight()
    window.addEventListener('resize', checkDecriptionHeight);
    return () => { window.removeEventListener('resize', checkDecriptionHeight);  }
  }, [ref, checkDecriptionHeight])

  const tallDescriptionClass = tallDescription ? 'tall' : ''

  return (
    <>
      <div className={'name '+tallDescriptionClass}><span>{companyData.name}</span></div>
      <div className={'description '+tallDescriptionClass} ><p ref={ref}>{companyData.description}</p></div>
      <div className={'stage '+tallDescriptionClass}><p className='small'>Current Stage</p> {companyData.stage}</div>
      <div className={'partneredIn '+tallDescriptionClass}><p className='small'>Partnered In </p>{year}</div>
      <div className={'lastcolumn '+tallDescriptionClass}>
        { tag && <p className='small sustainable'>{tag}</p> }
        <p className='small website'><a className="link--external--alt" href={companyData.website} target="_blank" rel="noopener noreferrer">Website</a></p>
      </div>
    </>
  )
}

function HomeOtherCompanies(props) {
  const data = props.data

  const entryName = data.header

  // Temporary safeguard against empty list during data migration
  if(!data.all_companies)
    return <HomeListEntry entryName={entryName} entryContent={null}/>

  const entryContent = <ul className="page-home__case-studies__other-companies">
    {data.all_companies.map( (company, i) => <HomeOtherCompanyEntry key={i} companyData={company} />)}
  </ul>

  return (
    <HomeListEntry entryName={entryName} entryContent={entryContent}/>
  )
}


function Home(props) {

  const history = useHistory();
  const [isPresent, safeToRemove] = usePresence()
  const curtainControls = useAnimation();

  const homeData = sanityProvider.findFirstOfType('homePage');

  /* Function called when clicking on the HomeCaseStudy <Link>, passed as an onClick callback */

  const navigateToStudy = (e, clickedIndex, studyUrl) => {
    e.preventDefault();

    /* Trigger custom animation */
    curtainControls.start(idx => ({
      y: idx === clickedIndex ? 0 : "100%",
      transition: { ease: "easeOut", duration: 0.5 }
    })).then( () => {
      history.push(studyUrl);
    });
  }

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  /* Initial HP animation */
  const hpBlockVariants = {
    "initial": {
      opacity: 1,
      y: 0
    }
  }

  const curtainVariants = {
    "initial": {
      y: "100%"
    }
  }

  /* Figure out if link is internal or external */
  let headerLink = null
  if(homeData.header_link_url) {
    headerLink = homeData.header_link_url.match(/^http/) ?
      <a className='link--inverted' href={homeData.header_link_url} target="_blank" rel="noopener noreferrer" >{homeData.header_link}</a>
      : <Link className='link--inverted' to={homeData.header_link_url}>{homeData.header_link}</Link>
  }

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key='home'>
      <section className='page-home'>

        <SanityHelmet documentTags={homeData.metaTags}/>
        <div className='grid'>
          <motion.div className='row' initial='inital' variants={hpBlockVariants} custom={-1}>
            <header className='off-t-2 off-d-3 col-m-4'>
              <BlockContent blocks={homeData.header}/>
              <p className='page-home__link'>
                { headerLink }
              </p>
            </header>
          </motion.div>
        </div>
        <ul className='page-home__case-studies col-t-2 col-d-3'>
          {homeData.case_studies.map( (study, i) => {
            const studyColor = colorForStudy(study)
            return (
              <motion.li initial='initial' variants={hpBlockVariants} key={`study-${i}`} className='has-animation'>
                <motion.div id={`study_curtain-${i}`} animate={curtainControls} variants={curtainVariants} custom={i} className="study_curtain" style={ { backgroundColor: studyColor } }/>
                <HomeCaseStudy study={study} onClick={ (e) => { navigateToStudy(e, i, urlBuilder(study)) } }/>
              </motion.li>
            )
          })}
          <motion.li initial='initial' variants={hpBlockVariants} custom={homeData.case_studies.length + 1} key={`study-${homeData.case_studies.length + 1}`} className='has-animation'>
            <HomeOtherCompanies data={homeData.other_companies} />
          </motion.li>
        </ul>
      </section>
    </motion.div>

  )
}

export { Home }
