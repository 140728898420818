import React, { useEffect, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react'
import classNames from 'classnames/bind';
import { motion, usePresence } from "framer-motion"
import { Waypoint } from 'react-waypoint';

import sanityProvider from '../../libs/sanity';
import ReponsiveSanityImage from '../common/ResponsiveImage';
import AnimatedImage from '../common/AnimatedImage';

function TeamMember(props) {
  const [isPresent, safeToRemove] = usePresence();
  const [revealed, setRevealed] = useState(false);

  const member = props.teamMember;

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  const heroIsVideo = !!member.video?.asset;

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key={member.name}>
      <section className='page-team-member grid'>
        <div className={ classNames('row', { revealed: revealed }) }>
          <Waypoint onEnter={() => setRevealed(true)} onLeave={(e) => { if(e.currentPosition === 'below') {setRevealed(false);} }}>
            <header className='col-m-4 col-t-6 off-t-2 col-d-9 off-d-3'>
              <h1>{member.name}</h1>
              <div className='page-team-member__details'>
                <div>{member.title}</div>
                <div className='page-team-member__details_links'>
                  { member.display_email &&
                    <span><a target="_blank" rel="noopener noreferrer" href={`mailto:${member.email}`} className="link--external--alt">email</a></span>
                  }
                  <span><a target="_blank" rel="noopener noreferrer" href={member.linkedInUrl} className="link--external--alt">linkedin</a></span>
                </div>
                <hr className='thin-line thin-line--bottom'/>
              </div>
            </header>
          </Waypoint>

          <div className='page-team-member__img col-m-4 col-t-5 off-t-2 col-d-5 off-d-3'>
            { heroIsVideo ?
              <video loop autoPlay muted src={member.video.asset.url}></video>
              :
              <AnimatedImage>
                <ReponsiveSanityImage asset={member.picture?.asset} maxWidth={1000}/>
              </AnimatedImage>
            }

          </div>

          { member.headline &&
          <div className='col-m-4 col-t-5 off-t-2 col-d-6 off-d-3'>
            <BlockContent className='headline' serializers={sanityProvider.blockContentSerializers} renderContainerOnSingleChild={true} blocks={member.headline}/>
          </div>
          }

        </div>

        { member.bio &&
        <div className='row'>
          <div className='page-team-member__bio col-m-4 col-t-5 off-t-2 col-d-6 off-d-3'>
            <BlockContent className='page-team-member__bio-block__copy' serializers={sanityProvider.blockContentSerializers} renderContainerOnSingleChild={true} blocks={member.bio}/>
          </div>
        </div>
        }

        { member.extra_pictures &&
        <div className='row page-team-member__extra-pictures'>
          { member.extra_pictures.map( (pic, i) => {
            return(
              <div key={i} className='page-team-member__extra-pic col-m-4'>
                <AnimatedImage>
                  <ReponsiveSanityImage asset={pic.asset} maxWidth={1000}/>
                </AnimatedImage>
              </div>
            )

          })}
        </div>
        }

        { member.closingStatement &&
        <div className='row'>
          <div className='page-team-member__closing col-m-4 off-t-2 col-t-3 off-d-3 col-d-3'>
            <BlockContent serializers={sanityProvider.blockContentSerializers} blocks={member.closingStatement}/>
          </div>
        </div>
        }


      </section>
    </motion.div>
  )
}

export default TeamMember;