import React from 'react';

import { Home } from '../components/pages/Home'
import CaseStudy from '../components/pages/CaseStudy'
import Team from '../components/pages/Team'
import TeamMember from '../components/pages/TeamMember'
import Updates from '../components/pages/Updates'
import Update from '../components/pages/Update'
import FourOFour from '../components/pages/FourOFour'
import TakeOver from '../components/pages/TakeOver'
import Impact from '../components/pages/Impact';

import sanityProvider from './sanity'

const routes = [
  /* Routes only available when no take over is happening */
  { allowDuringTakeover: false, exact: true, path: "/", component: Home },
  { allowDuringTakeover: false, exact: true, path: "/impact", component: Impact },
  { allowDuringTakeover: false, exact: true, path: "/companies", component: Home },
  { allowDuringTakeover: false, exact: true, path: "/companies/:id",
    render: (compProps) => {
        const { id } = compProps.match.params;
        const study = sanityProvider.findCaseStudyBySlug(id);
        return study === null ? <FourOFour/> : <CaseStudy study={study}/>;
      }
  },
  { allowDuringTakeover: false, exact: true, path: "/people", component: Team },
  { allowDuringTakeover: false, exact: true, path: "/people/:id",
    render: (compProps) => {
        const { id } = compProps.match.params;
        const teamMember = sanityProvider.findTeamMemberBySlug(id);
        return teamMember === null ? <FourOFour/> : <TeamMember teamMember={teamMember}/>;
      }
  },
  { allowDuringTakeover: false, exact: true, path: "/updates", component: Updates },

  /* Take over routes */
  { allowDuringTakeover: true, exact: true, path: "/", component: TakeOver },

  /* Routes available regardless of take over */
  { allowDuringTakeover: true, exact: true, path: "/updates/:id",
    render: (compProps) => {
      const { id } = compProps.match.params;
      const update = sanityProvider.findUpdateBySlug(id);
      return update === null ? <FourOFour/> : <Update update={update}/>;
    }
  },

  /* 404 */
  { allowDuringTakeover: true, path: "*", component: FourOFour }
]

/* Builder to easily change paths */
const urlBuilder = (document) => {
  if(!document) {
    console.warn("Invalid document object");
    return null;
  }
  switch(document._type) {
    case 'teamMember':
      return `/people/${document.slug.current}`;
    case 'caseStudy':
      return `/companies/${document.slug.current}`;
    case 'update':
      return `/updates/${document.slug.current}`;
    default:
      console.warn(`Unknown document type ${document._type}`);
      return '/';
  }
}

export default routes
export { urlBuilder }
