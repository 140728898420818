import ImgAsset0000 from '../../../../assets/manifesto/mercury/mercury-001.webp'
import ImgAsset0001 from '../../../../assets/manifesto/mercury/mercury-002.webp'
import ImgAsset0002 from '../../../../assets/manifesto/mercury/mercury-003.webp'
import ImgAsset0003 from '../../../../assets/manifesto/mercury/mercury-004.webp'
import ImgAsset0004 from '../../../../assets/manifesto/mercury/mercury-005.webp'
import ImgAsset0005 from '../../../../assets/manifesto/mercury/mercury-006.webp'
import ImgAsset0006 from '../../../../assets/manifesto/mercury/mercury-007.webp'
import ImgAsset0007 from '../../../../assets/manifesto/mercury/mercury-008.webp'
import ImgAsset0008 from '../../../../assets/manifesto/mercury/mercury-009.webp'
import ImgAsset0009 from '../../../../assets/manifesto/mercury/mercury-010.webp'
import ImgAsset0010 from '../../../../assets/manifesto/mercury/mercury-011.webp'
import ImgAsset0011 from '../../../../assets/manifesto/mercury/mercury-012.webp'
import ImgAsset0012 from '../../../../assets/manifesto/mercury/mercury-013.webp'
import ImgAsset0013 from '../../../../assets/manifesto/mercury/mercury-014.webp'
import ImgAsset0014 from '../../../../assets/manifesto/mercury/mercury-015.webp'
import ImgAsset0015 from '../../../../assets/manifesto/mercury/mercury-016.webp'
import ImgAsset0016 from '../../../../assets/manifesto/mercury/mercury-017.webp'
import ImgAsset0017 from '../../../../assets/manifesto/mercury/mercury-018.webp'
import ImgAsset0018 from '../../../../assets/manifesto/mercury/mercury-019.webp'
import ImgAsset0019 from '../../../../assets/manifesto/mercury/mercury-020.webp'
import ImgAsset0020 from '../../../../assets/manifesto/mercury/mercury-021.webp'
import ImgAsset0021 from '../../../../assets/manifesto/mercury/mercury-022.webp'
import ImgAsset0022 from '../../../../assets/manifesto/mercury/mercury-023.webp'
import ImgAsset0023 from '../../../../assets/manifesto/mercury/mercury-024.webp'
import ImgAsset0024 from '../../../../assets/manifesto/mercury/mercury-025.webp'
import ImgAsset0025 from '../../../../assets/manifesto/mercury/mercury-026.webp'
import ImgAsset0026 from '../../../../assets/manifesto/mercury/mercury-027.webp'
import ImgAsset0027 from '../../../../assets/manifesto/mercury/mercury-028.webp'
import ImgAsset0028 from '../../../../assets/manifesto/mercury/mercury-029.webp'
import ImgAsset0029 from '../../../../assets/manifesto/mercury/mercury-030.webp'
import ImgAsset0030 from '../../../../assets/manifesto/mercury/mercury-031.webp'
import ImgAsset0031 from '../../../../assets/manifesto/mercury/mercury-032.webp'
import ImgAsset0032 from '../../../../assets/manifesto/mercury/mercury-033.webp'
import ImgAsset0033 from '../../../../assets/manifesto/mercury/mercury-034.webp'
import ImgAsset0034 from '../../../../assets/manifesto/mercury/mercury-035.webp'
import ImgAsset0035 from '../../../../assets/manifesto/mercury/mercury-036.webp'
import ImgAsset0036 from '../../../../assets/manifesto/mercury/mercury-037.webp'
import ImgAsset0037 from '../../../../assets/manifesto/mercury/mercury-038.webp'
import ImgAsset0038 from '../../../../assets/manifesto/mercury/mercury-039.webp'
import ImgAsset0039 from '../../../../assets/manifesto/mercury/mercury-040.webp'
import ImgAsset0040 from '../../../../assets/manifesto/mercury/mercury-041.webp'
import ImgAsset0041 from '../../../../assets/manifesto/mercury/mercury-042.webp'
import ImgAsset0042 from '../../../../assets/manifesto/mercury/mercury-043.webp'
import ImgAsset0043 from '../../../../assets/manifesto/mercury/mercury-044.webp'
import ImgAsset0044 from '../../../../assets/manifesto/mercury/mercury-045.webp'
import ImgAsset0045 from '../../../../assets/manifesto/mercury/mercury-046.webp'
import ImgAsset0046 from '../../../../assets/manifesto/mercury/mercury-047.webp'
import ImgAsset0047 from '../../../../assets/manifesto/mercury/mercury-048.webp'
import ImgAsset0048 from '../../../../assets/manifesto/mercury/mercury-049.webp'
import ImgAsset0049 from '../../../../assets/manifesto/mercury/mercury-050.webp'
import ImgAsset0050 from '../../../../assets/manifesto/mercury/mercury-051.webp'
import ImgAsset0051 from '../../../../assets/manifesto/mercury/mercury-052.webp'
import ImgAsset0052 from '../../../../assets/manifesto/mercury/mercury-053.webp'
import ImgAsset0053 from '../../../../assets/manifesto/mercury/mercury-054.webp'
import ImgAsset0054 from '../../../../assets/manifesto/mercury/mercury-055.webp'
import ImgAsset0055 from '../../../../assets/manifesto/mercury/mercury-056.webp'
import ImgAsset0056 from '../../../../assets/manifesto/mercury/mercury-057.webp'
import ImgAsset0057 from '../../../../assets/manifesto/mercury/mercury-058.webp'
import ImgAsset0058 from '../../../../assets/manifesto/mercury/mercury-059.webp'
import ImgAsset0059 from '../../../../assets/manifesto/mercury/mercury-060.webp'
import ImgAsset0060 from '../../../../assets/manifesto/mercury/mercury-061.webp'
import ImgAsset0061 from '../../../../assets/manifesto/mercury/mercury-062.webp'
import ImgAsset0062 from '../../../../assets/manifesto/mercury/mercury-063.webp'
import ImgAsset0063 from '../../../../assets/manifesto/mercury/mercury-064.webp'
import ImgAsset0064 from '../../../../assets/manifesto/mercury/mercury-065.webp'
import ImgAsset0065 from '../../../../assets/manifesto/mercury/mercury-066.webp'
import ImgAsset0066 from '../../../../assets/manifesto/mercury/mercury-067.webp'
import ImgAsset0067 from '../../../../assets/manifesto/mercury/mercury-068.webp'
import ImgAsset0068 from '../../../../assets/manifesto/mercury/mercury-069.webp'
import ImgAsset0069 from '../../../../assets/manifesto/mercury/mercury-070.webp'
import ImgAsset0070 from '../../../../assets/manifesto/mercury/mercury-071.webp'
import ImgAsset0071 from '../../../../assets/manifesto/mercury/mercury-072.webp'
import ImgAsset0072 from '../../../../assets/manifesto/mercury/mercury-073.webp'
import ImgAsset0073 from '../../../../assets/manifesto/mercury/mercury-074.webp'
import ImgAsset0074 from '../../../../assets/manifesto/mercury/mercury-075.webp'
import ImgAsset0075 from '../../../../assets/manifesto/mercury/mercury-076.webp'
import ImgAsset0076 from '../../../../assets/manifesto/mercury/mercury-077.webp'
import ImgAsset0077 from '../../../../assets/manifesto/mercury/mercury-078.webp'
import ImgAsset0078 from '../../../../assets/manifesto/mercury/mercury-079.webp'
import ImgAsset0079 from '../../../../assets/manifesto/mercury/mercury-080.webp'
import ImgAsset0080 from '../../../../assets/manifesto/mercury/mercury-081.webp'
import ImgAsset0081 from '../../../../assets/manifesto/mercury/mercury-082.webp'
import ImgAsset0082 from '../../../../assets/manifesto/mercury/mercury-083.webp'
import ImgAsset0083 from '../../../../assets/manifesto/mercury/mercury-084.webp'
import ImgAsset0084 from '../../../../assets/manifesto/mercury/mercury-085.webp'
import ImgAsset0085 from '../../../../assets/manifesto/mercury/mercury-086.webp'
import ImgAsset0086 from '../../../../assets/manifesto/mercury/mercury-087.webp'
import ImgAsset0087 from '../../../../assets/manifesto/mercury/mercury-088.webp'
import ImgAsset0088 from '../../../../assets/manifesto/mercury/mercury-089.webp'
import ImgAsset0089 from '../../../../assets/manifesto/mercury/mercury-090.webp'
import ImgAsset0090 from '../../../../assets/manifesto/mercury/mercury-091.webp'
import ImgAsset0091 from '../../../../assets/manifesto/mercury/mercury-092.webp'
import ImgAsset0092 from '../../../../assets/manifesto/mercury/mercury-093.webp'
import ImgAsset0093 from '../../../../assets/manifesto/mercury/mercury-094.webp'
import ImgAsset0094 from '../../../../assets/manifesto/mercury/mercury-095.webp'


export default [
  ImgAsset0000,
  ImgAsset0001,
  ImgAsset0002,
  ImgAsset0003,
  ImgAsset0004,
  ImgAsset0005,
  ImgAsset0006,
  ImgAsset0007,
  ImgAsset0008,
  ImgAsset0009,
  ImgAsset0010,
  ImgAsset0011,
  ImgAsset0012,
  ImgAsset0013,
  ImgAsset0014,
  ImgAsset0015,
  ImgAsset0016,
  ImgAsset0017,
  ImgAsset0018,
  ImgAsset0019,
  ImgAsset0020,
  ImgAsset0021,
  ImgAsset0022,
  ImgAsset0023,
  ImgAsset0024,
  ImgAsset0025,
  ImgAsset0026,
  ImgAsset0027,
  ImgAsset0028,
  ImgAsset0029,
  ImgAsset0030,
  ImgAsset0031,
  ImgAsset0032,
  ImgAsset0033,
  ImgAsset0034,
  ImgAsset0035,
  ImgAsset0036,
  ImgAsset0037,
  ImgAsset0038,
  ImgAsset0039,
  ImgAsset0040,
  ImgAsset0041,
  ImgAsset0042,
  ImgAsset0043,
  ImgAsset0044,
  ImgAsset0045,
  ImgAsset0046,
  ImgAsset0047,
  ImgAsset0048,
  ImgAsset0049,
  ImgAsset0050,
  ImgAsset0051,
  ImgAsset0052,
  ImgAsset0053,
  ImgAsset0054,
  ImgAsset0055,
  ImgAsset0056,
  ImgAsset0057,
  ImgAsset0058,
  ImgAsset0059,
  ImgAsset0060,
  ImgAsset0061,
  ImgAsset0062,
  ImgAsset0063,
  ImgAsset0064,
  ImgAsset0065,
  ImgAsset0066,
  ImgAsset0067,
  ImgAsset0068,
  ImgAsset0069,
  ImgAsset0070,
  ImgAsset0071,
  ImgAsset0072,
  ImgAsset0073,
  ImgAsset0074,
  ImgAsset0075,
  ImgAsset0076,
  ImgAsset0077,
  ImgAsset0078,
  ImgAsset0079,
  ImgAsset0080,
  ImgAsset0081,
  ImgAsset0082,
  ImgAsset0083,
  ImgAsset0084,
  ImgAsset0085,
  ImgAsset0086,
  ImgAsset0087,
  ImgAsset0088,
  ImgAsset0089,
  ImgAsset0090,
  ImgAsset0091,
  ImgAsset0092,
  ImgAsset0093,
  ImgAsset0094,
]