import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import { ReactComponent as R7Logo } from '../../assets/r7-animated.svg';

import sanityProvider from '../../libs/sanity';
import throttle from 'lodash/throttle';
import { withRouter, matchPath } from "react-router";

import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import { colorForStudy } from '../pages/CaseStudy'

function getRoutes() {
  const globalConf = sanityProvider.findFirstOfType('globalConf');

  let routes = [
    { exact: true, to:'/', path:['/', '/companies/:id'], text: "Companies" },
    { exact: true, to:'/people', path:['/people', '/people/:id'], text: "People" },
  ];

  /* Make manifesto route conditional on ENV var */
  // if(process.env.REACT_APP_SHOW_MANIFESTO) {
  //   routes.unshift(
  //     { exact: true, to:'/manifesto', path:['/manifesto'], text: "Manifesto" },
  //   )
  // }

  routes.push(
    { exact: true, to:'https://careers.r7.vc/jobs', path:['https://careers.r7.vc/jobs'], text: "Careers", external: true }
  );

  routes.push(
    { exact: true, to:'/impact', path:['/impact'], text: "Impact" }
  );

  if(globalConf.showUpdates) {
    routes.push(
      { exact: true, to:'/updates', path:['/updates', '/updates/:id'], text: "Updates" }
    );
  }

  routes.push(
    { exact: true, to:'https://flowinc.app/login?redirectUrl=https:%2F%2Fflowinc.app%2Fdashboard',
      path:['https://flowinc.app/login?redirectUrl=https:%2F%2Fflowinc.app%2Fdashboard'], text: "LP Login", external: true }
  );

  return routes;
};

class Nav extends React.Component {

  stickyThreshold = 25;

  constructor(props) {
    super(props);

    this.state = {
      /* Sticky nav */
      isSticky: false,
    }

    /* Throttled scroll handler for sticky nav */
    this.handleScrollThrottled = throttle(this.handleScroll, 10).bind(this);
  }


  /* Set or unset sticky on scroll */
  handleScroll() {
    const scrollY = window.scrollY;
    const isSticky = scrollY > this.stickyThreshold;
    if(isSticky !== this.state.isSticky) {
      this.setState({
        isSticky: isSticky
      });
    }
  };

  componentDidMount() {
    /* Add event listener for sticky nav */
    window.addEventListener('scroll', this.handleScrollThrottled);
  }

  componentWillUnmount(prevProps) {
    /* Remove event listener for sticky nav */
    window.removeEventListener('scroll', this.handleScrollThrottled);
  }

  render() {


    const globalConf = sanityProvider.findFirstOfType('globalConf');
    const doTakeOver = globalConf.showTakeOver;

    /* Override logo color in study context */
    const logoStyle = {}
    const studyContext = matchPath(this.props.location.pathname, { path: '/companies/:id', exact: true } );
    const impactContext = matchPath(this.props.location.pathname, { path: '/impact', exact: true } );

    if(studyContext) {
      const { id } = studyContext.params;
      const study = sanityProvider.findCaseStudyBySlug(id);
      logoStyle.fill = colorForStudy(study)
    }
    else if(impactContext) {
      logoStyle.fill = `#009299`
    }

    const navClasses = classNames('navigation-top', {
      'navigation-top--sticky': this.state.isSticky && !impactContext,
      'navigation-top--mobile-no-lock': impactContext
    });

    return(
      <nav className={navClasses}>
        <div className='navigation-top__wrapper'>
          <ul className='navigation-top__list'>
            <li className='home home--main'><NavLink to='/'><R7Logo style={logoStyle}/></NavLink></li>
            {!doTakeOver &&
              <li className='pages' suppressHydrationWarning={true} >
                <span className='mobile-only'>
                  <MobileNav routes={getRoutes()}/>
                </span>
                <span className='tablet-and-desktop-only'>
                  <DesktopNav routes={getRoutes()}/>
                </span>
              </li>
            }
          </ul>
        </div>
      </nav>
    );
  }
}

// export default Nav;

const withRouterNav = withRouter(Nav);
export default withRouterNav