import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from '../components/App';

import { BrowserRouter } from 'react-router-dom';

import sanityProvider from '../libs/sanity';

async function initializeApp() {

  /* Are we allowing draft mode? */
  const params = new URLSearchParams(window.location.search);
  const draftMode = params.get('draft') === sanityProvider.draftModeSecret;

  /* And get Sanity data */
  let data = await sanityProvider.fetchInitialData(draftMode);

  ReactDOM.hydrate(
    <BrowserRouter>
      <App sanityData={data}/>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

initializeApp();
