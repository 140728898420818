import React, { useEffect, useState } from 'react';
import { motion, usePresence } from "framer-motion"
import { Waypoint } from 'react-waypoint';
import Revealer from '../common/Revealer'
import classNames from 'classnames/bind';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import ArrowLink from '../common/ArrowLink';
import { Helmet } from 'react-helmet';
import SanityHelmet from '../common/SanityHelmet';

import img_road from '../../assets/impact/michelle-spollen-c9MFM8rSMsQ-unsplash.webp'
import img_annie from '../../assets/impact/annie-spratt-U1mQ3wGcvtQ-unsplash.webp'

import img_bedrock from '../../assets/impact/companies/bedrock2.webp'
import img_ironox from '../../assets/impact/companies/ironox.webp'
import img_oculli from '../../assets/impact/companies/oculii.webp'
import img_overflow from '../../assets/impact/companies/overflow2.webp'
import img_xwing from '../../assets/impact/companies/X-Wing.webp'
import img_roam from '../../assets/impact/companies/roam2.webp'

import { ReactComponent as DownloadIcon } from '../../assets/arrow-download.svg'

import DesktopAnimations from './impact/desktopAnimations'
import AnimatedImage from '../common/AnimatedImage';

function RevealerWrapper(props) {

  const [revealed, setRevealed] = useState(false);

  const classes = classNames(props.className, {
    revealed: revealed
  })

  const WrapperElement = props.wrapperElement || 'div';

  return(
    <Waypoint onEnter={() => setRevealed(true)} onLeave={(e) => { if(e.currentPosition === 'below') {setRevealed(false);} }}>
      <WrapperElement className={classes}>
        {props.children}
      </WrapperElement>
    </Waypoint>
  )

}

function ImpactCompany(props) {

  const { company, sdgs, image, goal, goalTagline, copy, black } = props

  const classes = classNames('company-entry', {
    'company-entry--black': black
  })

  const imageClass = classNames('through-padding', 'col-m-4',  {
    'through-padding-left': black,
    'through-padding-right': !black
   })

  return(
    <div className={classes}>
      <div className='horizontal-wrapper'>
        <div className='grid'>

          <div className='row'>
            <div className='col-m-4 off-t-1 col-t-6 col-d-6 off-d-3'>
              <h2>{company}</h2>
                <RevealerWrapper className='company-sdg' wrapperElement="ul">
                  {sdgs.map((sdg, i) => {
                    return(
                      <li key={sdg.name}>
                      <Revealer>
                        <p className='sdg'>{sdg.name}</p>
                        <p>{sdg.description}</p>
                      </Revealer>
                    </li>
                    )
                  })}
                </RevealerWrapper>
            </div>
          </div>

          <div className='row'>
            <div className={imageClass}>
              <AnimatedImage className="img_ratio_wrap"><img src={image} alt=""/></AnimatedImage>
            </div>
          </div>

          <div className='row'>
            <div className='col-m-4 off-t-1 col-t-6 col-d-7 off-d-3'>
              <p className='goal-head'>GOAL</p>
              <p className='goal'>{goal}</p>
              { goalTagline && <p className='nomargin goal-sub'>({goalTagline})</p> }
            </div>
          </div>

          <div className='row'>
            <div className='col-m-4 off-t-1 col-t-6 col-d-6 off-d-3'>
              {copy}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Impact(props) {
  const [isPresent, safeToRemove] = usePresence();

  const [currentDeviceAnimations, setCurrentDeviceAnimations] = useState(null)

  const instantiateAnimationsForDevice = (deviceAnimations) => {
    window.requestAnimationFrame( () => {
      const animations = new deviceAnimations()
      animations.createAnimations()
      setCurrentDeviceAnimations(animations)
    })
  }

  /* This is responsible for creating the gasp animations and handling breakpoints */
  const createAnimations = () => {
    /* Create Animations */
    ScrollTrigger.matchMedia({

      "(max-width: 767px)": () => {
        instantiateAnimationsForDevice(DesktopAnimations)
        return () => { killAllScrollTriggers() }
      },
      "(min-width: 768px) and (max-width: 1023px)": () => {
        instantiateAnimationsForDevice(DesktopAnimations)
        return () => { killAllScrollTriggers() }
      },
      "(min-width: 1024px)": () => {
        instantiateAnimationsForDevice(DesktopAnimations)
        return () => { killAllScrollTriggers() }
      }
    });
  }

  const killAllScrollTriggers = () => {
    /* Manually destroy all scrolltriggers (matchmedia seems to be failing to catch them and does not properly destroy them upon viewport resize) */
    const sts = ScrollTrigger.getAll()
    sts.forEach( (st) => { st.kill() } )

    /* And clear all the targets possibly modified by the current animation class */
    if(currentDeviceAnimations) {
      currentDeviceAnimations.clearProps()
    }

  }

  const destroyAll = () => {
    /* Cleanup gsap */
    killAllScrollTriggers()

    /* Remove all media query callbacks */
    ScrollTrigger.clearMatchMedia("(max-width: 767px)")
    ScrollTrigger.clearMatchMedia("(min-width: 768px) and (max-width: 1023px)")
    ScrollTrigger.clearMatchMedia("(min-width: 1024px)")
  }


  useEffect(() => {
    createAnimations()
    return () => {
      destroyAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  const overrideOgTags = {
    title: "Impact Report",
    ogTitle: "Impact Report",
    description: "R7's Climate Impact and Alignment to the United Nations Sustainable Development Goals",
    ogDescription: "R7's Climate Impact and Alignment to the United Nations Sustainable Development Goals",
    ogImage: null
  }
  // console.log(img_road)

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key='impactPage'>
      <SanityHelmet documentTags={overrideOgTags}/>
      <Helmet>
        <meta property="og:image" content={img_road}/>
      </Helmet>
      <section className='page-impact' id='impact'>
        <div className='horizontal-wrapper'>
          <div className='grid '>
            <div className='row'>
              <header className='col-m-4 off-t-1 col-t-5 off-d-3 col-d-6'>
                <div className='download-cta'>
                  <a href={process.env.ASSETS_PATH + '/R7 2021 Impact Report.pdf'} target="_blank" rel="noopener noreferrer">
                    <DownloadIcon/>
                  </a>
                </div>
                <RevealerWrapper>
                  <Revealer><h2>Sustainability Impact Report</h2>
                  {/* <p className='date'>2021</p> */}</Revealer>
                  <hr className='thin-line'></hr>
                  <p className='copy'>R7's Climate Impact and Alignment to the United Nations Sustainable Development Goals</p>
                </RevealerWrapper>
              </header>
            </div>
            <div className='row'>
              <div className='col-m-4 col-t-8 col-d-12 header_image_container'>
                <img src={img_road} alt=""/>
              </div>
            </div>
            <div className='row'>
              {/* <RevealerWrapper className="through-padding through-padding-right col-m-4"> */}
              <RevealerWrapper className="col-d-9 off-d-3 col-t-7 off-t-1 col-m-4">
                <Revealer><h3>The Best Thing We Never Meant to Do</h3></Revealer>
                <hr className='thin-line'></hr>
              </RevealerWrapper>
            </div>
            <div className='row'>
              <div className='col-m-4 off-t-1 col-t-6 col-d-6 off-d-3'>
                <p className='with-initial'>Zero.
                  When we formed R7, that's the number of impact-focused companies we targeted. R7 was never meant to address societal issues like human health or environmental damage.
                  Rather, we were solely focused on supporting breakthrough technology and the entrepreneurs bringing it to the world.  And we still are.
                </p>
                <p>As we look back over the years, however, we've noticed a surprising trend. 45% of our portfolio is focused on the environment or climate change, 33% on improving health outcomes,
                   and 10% on philanthropy.
                </p>
                <p className='emphasis'>In other words, 88% of our dollars invested since 2018 went to supporting transformative companies with impact-oriented missions.</p>

                <h4>Impact Lives at the Edge of Innovation</h4>

                <p>Today's venture firms are more likely to invest in non-transformational companies — ones built upon minor technological advancements or ones that address trivial problems.
                  At R7, we instead run towards daring entrepreneurs building radically different companies in sectors that are desperate for transformation.  We want to see electric planes
                  fly on their own, robots grow our food with less water and fewer emissions, and charitable giving that happens with the click of a button.
                </p>
                <p>Making a real difference in the world requires the courage to leave the comfort of what you know.  This is where growth and discovery happens.  Where innovation is born
                  and impact begins.
                </p>

                <h4>Teams that Win </h4>

                <p>If you want to change the world, you have to achieve superiority and scale.  Good intentions are not enough.  Good ideas are not enough.
                  People make all the difference. </p>
                <p>We're proud to support some of the most wildly brilliant, ambitious, and mission-driven people on the planet. R7 exists to find these world-class teams,
                  elevate their vision and purpose, and propel them forward as they turn outdated industries on their heads and leave incumbents trembling in their wake.
                  These are the companies that change the world. These are the teams that win. </p>

              </div>
            </div>
            <div className='row'>
              <div className='through-padding through-padding-right col-m-4'>
                <AnimatedImage className="img_ratio_wrap"><img src={img_annie} alt=""/></AnimatedImage>
              </div>
            </div>

            <div className='row'>
              <div className='col-m-4 off-t-1 col-t-6 col-d-6 off-d-3'>
                <h2 className='red'>Doubling Down</h2>
                  <p>That's exactly what we're going to do.  After reviewing the profound results of our partnerships with radical innovators and the
                    companies they're building, we were moved, inspired, and motivated to do even better. </p>
                  <p>In fact, it's made each member of our team more responsible, more humble, and just downright better people.  We're not changing
                    our strategy or our approach, but we are more motivated than ever before to find world-class founders building radical innovation that will
                    forever impact our world. </p>

                  <h4>Measure What Matters</h4>

                  <p>In 2015, the UN developed a shared blueprint of peace and prosperity for people and the planet.  At its heart are 17 Sustainable Development Goals,
                    which are an urgent call to end poverty, improve health and education, reduce inequality, and spur economic growth &mdash; all while tackling climate
                    change and working to preserve our oceans and forests.</p>
                  <p>As we look to the future, we will use the UN Sustainable Development Goals (SDG's) as a framework to better understand and communicate the impact of
                    our portfolio and the future we're helping to create.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='scroller-panel .has-animation'>
          <div className='image-wrap .has-animation'>
            <div className='scroller-text'>
              <ul>
                <li className='active'>Save 2 billion tons of CO<sub>2</sub></li>
                <li>Improve health outcomes for 550M people</li>
                <li>Avoid 1.3M deaths worldwide</li>
                <li>Increase philanthropic giving by $250B</li>
                <li>Reduce agricultural land use by 93%</li>
                <li>Map 99% of the ocean floor</li>
              </ul>
            </div>
          </div>
        </div>

        <ImpactCompany
          black={true}
          company="Iron Ox"
          sdgs={[
            { name: "UN SDG 6", description: "Ensure availability and sustainable management of water and sanitation for all"},
            { name: "UN SDG 9", description: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation"},
            { name: "UN SDG 12", description: "Ensure sustainable consumption and production patterns"},
            { name: "UN SDG 13", description: "Take urgent action to combat climate change and its impacts"},
            { name: "UN SDG 15", description: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss"},
          ]}
          image={img_ironox}
          goal={<>Save .75 Gt of CO<sub>2</sub>, reduce water use by 90% and land use by 93%</>}
          goalTagline="Equivalent to eliminating 163 million cars off the road or 1.5% of global emissions"
          copy={<>
            <h3>Agriculture Today</h3>
            <p>Our food, and how it's grown, is one of the leading contributors to climate change.</p>
            <p>From tilling the land, to pumping it full of artificial fertilizers, to shipping crops an average of 2,000 miles before they reach your grocery store, our agricultural systems generate massive amounts of greenhouse gases and environmental damage.  They also consume 70% of the earth's freshwater, with 90% of that water never reaching the plant.</p>
            <p>The biggest offender of all is food waste - 40% of our food is thrown away before it ever reaches the shelf.  Decomposing food not only wastes the land, energy, and water used in its production, it also creates methane, a greenhouse gas 25x more potent than CO<sub>2</sub>.</p>
            <h3>The Future of Agriculture</h3>
            <p>Iron Ox is on a mission to make the food we eat carbon-negative.  This requires a complete rethinking of our food systems.</p>
            <p>By growing food year-round and closer to where it is consumed, Iron Ox grows what we need, when we need it, and where we need it - putting a massive dent in the 40% of food wasted today.  Their breakthroughs in robotic precision, hydroponic growing, and machine learning give plants exactly what they need to thrive - no more, no less.  </p>
            <p>These methods use 90% less water,  93% less land - enabling top-soil restoration and reforestation, and more sustainable inputs like renewable energy and eco-friendly fertilizers.  Thanks to Iron Ox, the food you eat will soon be more nutritious, more delicious and significantly better for  the planet. </p>
          </>
          }
        />

        <ImpactCompany
          black={false}
          company="Overflow"
          sdgs={[
            { name: "UN SDG 1", description: "End poverty in all its forms everywhere" },
            { name: "UN SDG 2", description: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture" },
            { name: "UN SDG 4", description: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all" },
            { name: "UN SDG 10", description: "Reduce inequality within and among countries" },
          ]}
          image={img_overflow}
          goal="Increase philanthropic giving by $250 billion by 2030"
          goalTagline="$450B was donated in the US in 2020"
          copy={<>
            <h3>Philanthropy Today</h3>
            <p>As the US enters the largest generational wealth transfer in its history, $30 trillion is expected to shift from baby boomers to younger generations.  This presents a unique opportunity for philanthropic organizations to help those recipients use their newfound wealth to give back.</p>
            <p>However, the stocks, bonds, and other non-cash assets that make up the vast majority of this wealth have historically made up only 20% of charitable donations.  Why?  Because it's inefficient, complicated, and time consuming to donate them.  </p>
            <h3>The Future of Philanthropy</h3>
            <p>Overflow makes it possible to donate stocks directly to nonprofits and churches with the click of a button.  Easy.  Simple.  Research shows that individuals are up to 70% more generous when donating stocks, and average donations made through Overflow are actually 47x larger than those made with cash or credit cards.</p>
            <p>Over the next decade, the Overflow platform has the potential to drive an additional $250B in donations, empowering the charitable organizations solving today's most pressing problems. </p>
          </>
          }
        />

        <ImpactCompany
          black={true}
          company="Bedrock"
          sdgs={[
            {name: "UN SDG 14", description: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development" },
            {name: "UN SDG 7 (Enabling)", description: "Ensure access to affordable, reliable, sustainable and modern energy for all" },
          ]}
          image={img_bedrock}
          goal="Map 99% of the ocean floor"
          goalTagline="Less than 1% of our oceans are mapped at 1m resolution"
          copy={<>
            <h3>Oceanic Exploration Today</h3>
            <p>Oceans remain the final unexplored frontier of our planet, covering 70% of the earth's surface.  Understanding our oceans is critical not only for protecting these ancient ecosystems, but also for protecting our way of life:  food, weather, power, safety, shipping.  Almost every part of daily life is shaped by the ocean. </p>
            <p>Today, however, less than 1% of the ocean floor has been mapped at a resolution to properly support these critical areas. </p>
            <h3>The Future of Oceanic Exploration</h3>
            <p>Bedrock is creating 50x higher resolution maps of the seafloor with autonomous underwater vehicles.  These vehicles will forever change oceanic exploration by making it cheaper, safer, faster, and less damaging. </p>
            <p>The maps generated by Bedrock will provide a first line of defense against climate change - improving our understanding of climate systems, unlocking offshore renewable energy, and enabling deep sea carbon storage.  </p>
          </>
          }
        />

        <ImpactCompany
          black={false}
          company="Xwing"
          sdgs={
            [{name: "UN SDG 7", description: "Ensure access to affordable, reliable, sustainable and modern energy for all"}]
          }
          image={img_xwing}
          goal={<>Save 1.25 billion tons of CO<sub>2</sub></>}
          goalTagline="Equivalent to 219 million cars off the road or 2% of global emissions"
          copy={<>
            <h3>Aviation Today</h3>
            <p>Every year, trillions of dollars worth of goods are shipped around the world, releasing an enormous amount of carbon into the atmosphere. Air cargo alone emits up to 50x
              more carbon per ton than ships, but the rise of next-day delivery is forcing suppliers to value speed over sustainability.
            </p>
            <p>These trends continue to drive growth in aviation - which already makes up 2% of global emissions. If left unaddressed, it will make it nearly impossible to achieve a
              net-zero world by 2050.
            </p>
            <h3>The Future of Aviation</h3>
            <p>By removing the pilot from the plane, Xwing will optimize every aspect of the aviation supply chain - more efficient routes, less taxi and loading times, and more packages per flight.
              This means less energy and a 15% emssions reduction.
            </p>
          </>
          }
        />

        <ImpactCompany
          black={true}
          company="Roam"
          sdgs={[
            {name: "UN SDG 3", description: "Ensure healthy lives and promote well-being for all at all ages"},
          ]}
          image={img_roam}
          goal="Improve Health Outcomes for 500+ million People "
          goalTagline="6.3% of world population"
          copy={<>
            <h3>Human Mobility Today</h3>
            <p>Knee problems are the most pervasive mobility limitation in the world, with over 500 million people receiving treatment in 2020.  Unfortunately, today's treatment options can do more harm than good.  Opioids carry a well documented risk of dependency, claiming 50 thousand lives each year; surgery costs an average of $45,000 and inflicts significant bodily trauma; and physical therapy requires complete and ongoing compliance with marginal benefit.</p>
            <p>Elderly patients and those with chronic disease are desperate for better solutions.  </p>
            <h3>The Future of Human Mobility</h3>
            <p>Roam is expanding the boundaries of human movement with a category-defining robotic wearable that offers the safest and most comprehensive solution for knee pain and enhanced performance.  This device delivers a paradigm shift in performance, comfort, and affordability - reaching new patient populations and allowing them to return to healthier, more fulfilling lives.  </p>
            <p>Patients receiving treatment with Roam report a 50% decrease in pain, a 67% increase in mobility, and a 10x increase in endurance…all while avoiding the costs and risks associated with traditional treatments. </p>
          </>
          }
        />


        <ImpactCompany
          black={false}
          company="Oculii"
          sdgs={[
            {name: "UN SDG 3 (enabling)", description: "Ensure healthy lives and promote well-being for all at all ages"},
          ]}
          image={img_oculli}
          goal="Avoid 1.3M deaths and 50M injuries "
          goalTagline="1 death every 24 seconds"
          copy={<>
            <h3>Driving Today</h3>
            <p>Each year, there are 1.3M traffic deaths and 50M serious injuries around the world.  Most of these accidents are caused by poor decision making, distraction, or incapacitation.  As technology improves, our ability to avoid this tragedy becomes obligation. </p>
            <h3>The Future of Driving</h3>
            <p>Oculii is revolutionizing vehicle detection by developing software that improves radar resolution 100x over existing solutions.  By radically enhancing detection, Oculii will improve Advanced Driver-Assistance Systems – with a potential for a 7x reduction in collisions. </p>
            <p>This breakthrough will also accelerate the transition to an autonomous vehicle ecosystem capable of eliminating most (if not all) human-caused deaths and injuries.   </p>
          </>
          }
        />

        <div className='manifesto-cta-wrap'>
          <div className='horizontal-wrapper  manifesto_link'>
            <ArrowLink external={true} to='https://medium.com/r7partners/solving-real-problems-yields-incredible-results-1e1c2c823f31'>Next Chapter - Our 2013 Manifesto</ArrowLink>
          </div>
        </div>

      </section>

    </motion.div>
  )
}

export default Impact;
