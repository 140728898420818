import React from 'react';
import { matchPath } from "react-router";
import { Route, Switch, useLocation } from 'react-router-dom';
import sanityProvider from '../libs/sanity';
import { Helmet } from "react-helmet";
import { AnimatePresence } from "framer-motion";
import ScrollMemory from 'react-router-scroll-memory';
import ReactGA from 'react-ga';
import { default as ReactGA4 } from "react-ga4";

/* Components & Pages */
import Nav from './layout/Nav';
import { Footer } from './layout/Footer';
import Manifesto from './pages/manifesto/Manifesto'

import routes from '../libs/routes'

/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

/* Main stylesheet */
import '../styles/App.scss';

/* Initialize font awesome */
library.add(faDownload, faCircle, faLinkedin, faTwitter);

function DraftModeNotice(props) {
  return (
    <div className='draft-mode-notice'>DRAFT MODE</div>
  )
}

function RoutedPages(props) {
  const location = useLocation()
  const globalConf = sanityProvider.findFirstOfType('globalConf')
  const doTakeOver = globalConf.showTakeOver

  return(
    <AnimatePresence>
      <div id='main' className={props.includePageWrap ? 'horizontal-wrapper' : ''} key={location.pathname}>
        <Switch location={location} key={location.pathname}>

          { routes.map( (route, i) => {
            if(route.allowDuringTakeover || !doTakeOver) {
              return <Route key={i} {...route} />
            }
            return null
          })}

        </Switch>
        { !props.noFooter &&
          <div className={!props.includePageWrap ? 'horizontal-wrapper' : ''}>
            <Footer/>
          </div>
        }
      </div>

    </AnimatePresence>
  )
}

let GAInitialized = false;
let GA4Initialized = false;

function App(props) {

  const location = useLocation();

  /* GA */
  React.useEffect(() => {
    /* No SSR ga */
    if(typeof window !== undefined && process.env.REACT_APP_GA_TRACKER_ID) {
      if(!GAInitialized) {
        GAInitialized = true;
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKER_ID);
      }
      ReactGA.pageview(location.pathname);
    }
    if(typeof window !== undefined && process.env.REACT_APP_GA4_TRACKER_ID) {
      if(!GA4Initialized) {
        GA4Initialized = true;
        ReactGA4.initialize(process.env.REACT_APP_GA4_TRACKER_ID);
      }
      ReactGA4.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  /* Switch layout for manifesto page */
  const onManisfestoPage = process.env.REACT_APP_SHOW_MANIFESTO && matchPath(location.pathname, { path: '/manifesto', exact: true } );
  const onImpactPage = matchPath(location.pathname, { path: '/impact', exact: true } );

  return (
    <>
      <ScrollMemory />
      { onManisfestoPage ?
          <Manifesto/>
        :
          <>
            <Nav/>
            { sanityProvider.draftMode && <DraftModeNotice/> }
            <Helmet defaultTitle='R7' titleTemplate="%s | R7" />
            <RoutedPages includePageWrap={!onImpactPage} noFooter={onImpactPage}/>
          </>
      }
    </>

  )
}

export default App;
