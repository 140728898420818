import React from 'react';
import PropTypes from 'prop-types';
import sanityProvider from '../../libs/sanity';

import imageMissing from '../../assets/img_missing.png.webp'

import compact from 'lodash/compact'

const defaultMultipliers = [
  0.5, 1, 1.5, 2, 3
];

function buildOptions(width, squareCrop = false) {
  // return squareCrop
  //   ? { w: width, h: width, fit: 'crop', crop: 'center' }
  //   : { w: width, fit: 'max' };
  let options = squareCrop
    ? { w: width, h: width, fit: 'crop', crop: 'center' }
    : { w: width, fit: 'max' };
  options.fm = 'webp'
  return options
}

function ResponsiveSanityImage(props) {

  /* Props */
  const { maxWidth, squareCrop, alt, asset, ...otherProps } = props;

  /* Defaults */
  const multipliers = props.multipliers || defaultMultipliers;

  /* Get asset or fallback */
  const imageUrl = sanityProvider.getImageUrl( asset );
  if(!asset || !imageUrl) {
    return <img alt='missing' src={imageMissing} {...otherProps} />;
  }

  /* Get stored asset dimensions */
  const { height, width } = asset.metadata.dimensions;

  /* Build URLs */
  const srcSet = compact(multipliers.map( (multiplier) => {
    /* Get width for current DPR multiplier */
    const multipliedWidth = Math.ceil(maxWidth * multiplier);

    /* DPR width is greater than asset's width, don't include srcset, let the browser do the upsizing if needed */
    if(multipliedWidth > width) {
      return null;
    }

    const url = sanityProvider.applyImageUrlOptions(imageUrl, buildOptions(multipliedWidth, squareCrop));
    return `${url} ${multipliedWidth}w`;
  })).join(",");

  const src = sanityProvider.applyImageUrlOptions(imageUrl, buildOptions(maxWidth, squareCrop));

  /* Passing original dimensions of the image so image placeholders can be properly rendered before images are actually downloaded */
  return(
    <img height={height} width={width} alt={alt || ""} srcSet={srcSet} src={src} {...otherProps}/>
  )
}

ResponsiveSanityImage.propTypes = {
  asset: PropTypes.object.isRequired,
  maxWidth: PropTypes.number.isRequired,
  multipliers: PropTypes.array,

};

export default ResponsiveSanityImage;