import React from 'react';
import classNames from 'classnames/bind';
import { Waypoint } from 'react-waypoint';
import { motion, useAnimation } from "framer-motion"

function AnimatedImage(props) {

  const controls = useAnimation();

  const duration = 0.4,
  deltaY = 80;

  const variants = {
    "hidden": {
      opacity: 0,
      transition: { ease: "easeOut", duration: duration }
    },
    "enterFromLanding": {
      x: [-10,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    },
    "enterFromAbove": {
      x: [0,0],
      y:[0,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: 0.01, times:[0,1] }
    },
    "enterFromBelow": {
      x: [0,0],
      y:[deltaY,0],
      opacity: [0,1],
      transition: { ease: "easeOut", duration: duration, times:[0,1] }
    }
  }

  function topWaypointIn(e) {
    if(e.previousPosition === "below") {
      controls.start("enterFromBelow");
    }
    else if(e.previousPosition === "above") {
      controls.start("enterFromAbove");
    }
    else if (e.previousPosition === undefined) {
      controls.start("enterFromLanding");
    }
  }

  const { className, ...rest } = props;

  const motionClassName = classNames('has-animation', className);

  return (
    <>
      <Waypoint onEnter={(e) => {topWaypointIn(e)}} />
      <motion.div {...rest} animate={controls} initial="hidden" variants={variants} className={motionClassName}>
        {props.children}
      </motion.div>
    </>
  )
}

export default AnimatedImage;