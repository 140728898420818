import React, { useEffect } from 'react';
import { motion, usePresence } from "framer-motion"

function TakeOver(props) {
  const [isPresent, safeToRemove] = usePresence();

  /* This is needed as we're manually triggering a custom animation and then navigating away immediately (not using the Framer Motion exit prop) */
  useEffect(() => {
    !isPresent && safeToRemove()
  }, [isPresent, safeToRemove]);

  return (
    <motion.div transition={{ duration: 1 }} animate={{opacity:1}} initial={{opacity:1}} key={`takeover`}>
      <section className='page-take-over'>
        <div className='container'>
          <div className='logo'>
          </div>
          <a href='https://medium.com/r7partners/solving-real-problems-yields-incredible-results-1e1c2c823f31' rel="noopener noreferrer" target="_blank">Our Manifesto</a>
        </div>
      </section>
    </motion.div>
  )
}

export default TakeOver;