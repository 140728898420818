import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { motion, useAnimation, AnimationControls } from "framer-motion";

function AnimatedArrow(props) {

  /* Allow passing animation from parent */

  // const controls = useAnimation();
  // const realControls = props.controls || controls;
  // console.log(realControls);
  const controls = props.controls;
  const animationSpeed = 0.2;
  const linkArrowSize = "12px"; // This must match the css variable ($link-arrow-size)
  const variants = {
    "initial": {
      backgroundPosition: `-${linkArrowSize} center`
    },
    "enter": {
      backgroundPosition: "0px center",
      transition: { duration: animationSpeed },
    },
    "exit": {
      backgroundPosition: [null, `${linkArrowSize} center`, `-${linkArrowSize} center`],
      transition: { duration: animationSpeed, times: [0,1,1] },
    }
  }

  return (
    <motion.span className='link--arrow__arrow' variants={variants} animate={controls} initial="initial"></motion.span>
  )
}

AnimatedArrow.propTypes = {
  controls: PropTypes.instanceOf(AnimationControls).isRequired
};

function ArrowLink(props) {

  const controls = useAnimation();

  function over(e)  {
    controls.start("enter")
  }

  function out(e)  {
    controls.start("exit")
  }

  const words = props.children.split(" ");
  const lastWord = words.pop();

  const {external, ...remainaingPros} = props;

  if(external) {
    return (
      <a className='link--arrow' onMouseOver={over} onMouseOut={out} href={props.to} target="_blank" rel="noopener noreferrer">
      {words.join(" ")}{" "}
        <span style={{whiteSpace: 'nowrap'}}>{lastWord}<AnimatedArrow controls={controls} /></span>
      </a>
    );
  }

  return (
    <Link className='link--arrow' {...remainaingPros} onMouseOver={over} onMouseOut={out}>
    {words.join(" ")}{" "}
      <span style={{whiteSpace: 'nowrap'}}>{lastWord}<AnimatedArrow controls={controls} /></span>
    </Link>
  );
};


export { ArrowLink as default, AnimatedArrow };