import ImgAsset0000 from '../../../../assets/manifesto/moonphases/moonphases-001.webp'
import ImgAsset0001 from '../../../../assets/manifesto/moonphases/moonphases-002.webp'
import ImgAsset0002 from '../../../../assets/manifesto/moonphases/moonphases-003.webp'
import ImgAsset0003 from '../../../../assets/manifesto/moonphases/moonphases-004.webp'
import ImgAsset0004 from '../../../../assets/manifesto/moonphases/moonphases-005.webp'
import ImgAsset0005 from '../../../../assets/manifesto/moonphases/moonphases-006.webp'
import ImgAsset0006 from '../../../../assets/manifesto/moonphases/moonphases-007.webp'
import ImgAsset0007 from '../../../../assets/manifesto/moonphases/moonphases-008.webp'
import ImgAsset0008 from '../../../../assets/manifesto/moonphases/moonphases-009.webp'
import ImgAsset0009 from '../../../../assets/manifesto/moonphases/moonphases-010.webp'
import ImgAsset0010 from '../../../../assets/manifesto/moonphases/moonphases-011.webp'
import ImgAsset0011 from '../../../../assets/manifesto/moonphases/moonphases-012.webp'
import ImgAsset0012 from '../../../../assets/manifesto/moonphases/moonphases-013.webp'
import ImgAsset0013 from '../../../../assets/manifesto/moonphases/moonphases-014.webp'
import ImgAsset0014 from '../../../../assets/manifesto/moonphases/moonphases-015.webp'
import ImgAsset0015 from '../../../../assets/manifesto/moonphases/moonphases-016.webp'
import ImgAsset0016 from '../../../../assets/manifesto/moonphases/moonphases-017.webp'
import ImgAsset0017 from '../../../../assets/manifesto/moonphases/moonphases-018.webp'
import ImgAsset0018 from '../../../../assets/manifesto/moonphases/moonphases-019.webp'
import ImgAsset0019 from '../../../../assets/manifesto/moonphases/moonphases-020.webp'
import ImgAsset0020 from '../../../../assets/manifesto/moonphases/moonphases-021.webp'
import ImgAsset0021 from '../../../../assets/manifesto/moonphases/moonphases-022.webp'
import ImgAsset0022 from '../../../../assets/manifesto/moonphases/moonphases-023.webp'
import ImgAsset0023 from '../../../../assets/manifesto/moonphases/moonphases-024.webp'
import ImgAsset0024 from '../../../../assets/manifesto/moonphases/moonphases-025.webp'
import ImgAsset0025 from '../../../../assets/manifesto/moonphases/moonphases-026.webp'
import ImgAsset0026 from '../../../../assets/manifesto/moonphases/moonphases-027.webp'
import ImgAsset0027 from '../../../../assets/manifesto/moonphases/moonphases-028.webp'
import ImgAsset0028 from '../../../../assets/manifesto/moonphases/moonphases-029.webp'
import ImgAsset0029 from '../../../../assets/manifesto/moonphases/moonphases-030.webp'
import ImgAsset0030 from '../../../../assets/manifesto/moonphases/moonphases-031.webp'
import ImgAsset0031 from '../../../../assets/manifesto/moonphases/moonphases-032.webp'
import ImgAsset0032 from '../../../../assets/manifesto/moonphases/moonphases-033.webp'
import ImgAsset0033 from '../../../../assets/manifesto/moonphases/moonphases-034.webp'
import ImgAsset0034 from '../../../../assets/manifesto/moonphases/moonphases-035.webp'
import ImgAsset0035 from '../../../../assets/manifesto/moonphases/moonphases-036.webp'
import ImgAsset0036 from '../../../../assets/manifesto/moonphases/moonphases-037.webp'
import ImgAsset0037 from '../../../../assets/manifesto/moonphases/moonphases-038.webp'
import ImgAsset0038 from '../../../../assets/manifesto/moonphases/moonphases-039.webp'
import ImgAsset0039 from '../../../../assets/manifesto/moonphases/moonphases-040.webp'
import ImgAsset0040 from '../../../../assets/manifesto/moonphases/moonphases-041.webp'
import ImgAsset0041 from '../../../../assets/manifesto/moonphases/moonphases-042.webp'
import ImgAsset0042 from '../../../../assets/manifesto/moonphases/moonphases-043.webp'
import ImgAsset0043 from '../../../../assets/manifesto/moonphases/moonphases-044.webp'
import ImgAsset0044 from '../../../../assets/manifesto/moonphases/moonphases-045.webp'
import ImgAsset0045 from '../../../../assets/manifesto/moonphases/moonphases-046.webp'
import ImgAsset0046 from '../../../../assets/manifesto/moonphases/moonphases-047.webp'
import ImgAsset0047 from '../../../../assets/manifesto/moonphases/moonphases-048.webp'
import ImgAsset0048 from '../../../../assets/manifesto/moonphases/moonphases-049.webp'
import ImgAsset0049 from '../../../../assets/manifesto/moonphases/moonphases-050.webp'
import ImgAsset0050 from '../../../../assets/manifesto/moonphases/moonphases-051.webp'
import ImgAsset0051 from '../../../../assets/manifesto/moonphases/moonphases-052.webp'
import ImgAsset0052 from '../../../../assets/manifesto/moonphases/moonphases-053.webp'
import ImgAsset0053 from '../../../../assets/manifesto/moonphases/moonphases-054.webp'
import ImgAsset0054 from '../../../../assets/manifesto/moonphases/moonphases-055.webp'
import ImgAsset0055 from '../../../../assets/manifesto/moonphases/moonphases-056.webp'
import ImgAsset0056 from '../../../../assets/manifesto/moonphases/moonphases-057.webp'
import ImgAsset0057 from '../../../../assets/manifesto/moonphases/moonphases-058.webp'
import ImgAsset0058 from '../../../../assets/manifesto/moonphases/moonphases-059.webp'
import ImgAsset0059 from '../../../../assets/manifesto/moonphases/moonphases-060.webp'
import ImgAsset0060 from '../../../../assets/manifesto/moonphases/moonphases-061.webp'
import ImgAsset0061 from '../../../../assets/manifesto/moonphases/moonphases-062.webp'
import ImgAsset0062 from '../../../../assets/manifesto/moonphases/moonphases-063.webp'
import ImgAsset0063 from '../../../../assets/manifesto/moonphases/moonphases-064.webp'
import ImgAsset0064 from '../../../../assets/manifesto/moonphases/moonphases-065.webp'
import ImgAsset0065 from '../../../../assets/manifesto/moonphases/moonphases-066.webp'
import ImgAsset0066 from '../../../../assets/manifesto/moonphases/moonphases-067.webp'
import ImgAsset0067 from '../../../../assets/manifesto/moonphases/moonphases-068.webp'
import ImgAsset0068 from '../../../../assets/manifesto/moonphases/moonphases-069.webp'
import ImgAsset0069 from '../../../../assets/manifesto/moonphases/moonphases-070.webp'
import ImgAsset0070 from '../../../../assets/manifesto/moonphases/moonphases-071.webp'
import ImgAsset0071 from '../../../../assets/manifesto/moonphases/moonphases-072.webp'
import ImgAsset0072 from '../../../../assets/manifesto/moonphases/moonphases-073.webp'
import ImgAsset0073 from '../../../../assets/manifesto/moonphases/moonphases-074.webp'
import ImgAsset0074 from '../../../../assets/manifesto/moonphases/moonphases-075.webp'
import ImgAsset0075 from '../../../../assets/manifesto/moonphases/moonphases-076.webp'
import ImgAsset0076 from '../../../../assets/manifesto/moonphases/moonphases-077.webp'
import ImgAsset0077 from '../../../../assets/manifesto/moonphases/moonphases-078.webp'
import ImgAsset0078 from '../../../../assets/manifesto/moonphases/moonphases-079.webp'


export default [
  ImgAsset0000,
  ImgAsset0001,
  ImgAsset0002,
  ImgAsset0003,
  ImgAsset0004,
  ImgAsset0005,
  ImgAsset0006,
  ImgAsset0007,
  ImgAsset0008,
  ImgAsset0009,
  ImgAsset0010,
  ImgAsset0011,
  ImgAsset0012,
  ImgAsset0013,
  ImgAsset0014,
  ImgAsset0015,
  ImgAsset0016,
  ImgAsset0017,
  ImgAsset0018,
  ImgAsset0019,
  ImgAsset0020,
  ImgAsset0021,
  ImgAsset0022,
  ImgAsset0023,
  ImgAsset0024,
  ImgAsset0025,
  ImgAsset0026,
  ImgAsset0027,
  ImgAsset0028,
  ImgAsset0029,
  ImgAsset0030,
  ImgAsset0031,
  ImgAsset0032,
  ImgAsset0033,
  ImgAsset0034,
  ImgAsset0035,
  ImgAsset0036,
  ImgAsset0037,
  ImgAsset0038,
  ImgAsset0039,
  ImgAsset0040,
  ImgAsset0041,
  ImgAsset0042,
  ImgAsset0043,
  ImgAsset0044,
  ImgAsset0045,
  ImgAsset0046,
  ImgAsset0047,
  ImgAsset0048,
  ImgAsset0049,
  ImgAsset0050,
  ImgAsset0051,
  ImgAsset0052,
  ImgAsset0053,
  ImgAsset0054,
  ImgAsset0055,
  ImgAsset0056,
  ImgAsset0057,
  ImgAsset0058,
  ImgAsset0059,
  ImgAsset0060,
  ImgAsset0061,
  ImgAsset0062,
  ImgAsset0063,
  ImgAsset0064,
  ImgAsset0065,
  ImgAsset0066,
  ImgAsset0067,
  ImgAsset0068,
  ImgAsset0069,
  ImgAsset0070,
  ImgAsset0071,
  ImgAsset0072,
  ImgAsset0073,
  ImgAsset0074,
  ImgAsset0075,
  ImgAsset0076,
  ImgAsset0077,
  ImgAsset0078,
]