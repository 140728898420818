import { TimelineMax, TweenMax } from 'gsap'
import merge from 'deepmerge'
import flattenDeep from 'lodash/flattenDeep'
class ResponsiveAnimations {

  constructor(dataForAnimations) {
    this.dataForAnimations = dataForAnimations;
    this.clearPropsTargets = []
  }

  /* This allows the Animation Class to keep track of all DOM elements that need to be cleared when destroying the animation */
  tagForClear(target){
    this.clearPropsTargets.push(target)
  }

  /* This should be called when destroying the animation */
  clearProps() {
    TweenMax.set( flattenDeep(this.clearPropsTargets), { clearProps: true } )
  }

  ref(refName) {
    return this.dataForAnimations.references[refName].current
  }

  timelineParams(target, options = {} ) {
    return merge(
      {
        paused: true,
        scrollTrigger: {
          trigger: target,
          scrub: true,
          // markers: true,
          start: 'top 0%',
        }
      }, options)
  }

  createPageTimeline(scenes) {
    return new TimelineMax()
      .set("#manifesto", { opacity: 1 }, 0)
      .add(scenes)
  }

}

export default ResponsiveAnimations