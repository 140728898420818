import React from 'react';
import { Helmet } from "react-helmet";
// import { defaults, pick } from 'lodash';
import defaults from 'lodash/defaults';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import sanityProvider from '../../libs/sanity';


function SanityHelmet(props) {
  const validTags = ['title', 'description','ogTitle','ogDescription','ogImage'];

  /* Document metaTags passed to helmet - should be a metaTags structure */
  const documentTags = pick(props.documentTags, validTags);

  /* Fallback object passed in props for local fallback or override by the parent */
  const fallbackTags = pick(props.fallbackTags || {}, validTags);

  /* Global meta options fallback */
  // const globalTagsObject = sanityProvider.findFirstOfType('metaTags') || {};
  const globalTagsObject = sanityProvider.findFirstOfType('globalConf').metaDefaults || {};
  const globalTags = pick(globalTagsObject || {}, validTags);

  /* Use lodash defaults to properly prioritize */
  const metadata = defaults(documentTags, fallbackTags, globalTags);

  return (
    <Helmet>
      {metadata.title && (<title>{metadata.title}</title>)}
      {metadata.description && (<meta name="description" content={metadata.description}/>)}
      {metadata.ogTitle && (<meta property="og:title" content={metadata.ogTitle}/>)}
      {metadata.ogImage && (<meta property="og:image" content={sanityProvider.getImageUrl(metadata.ogImage.asset)}/>)}
      {metadata.ogDescription && (<meta property="og:description" content={metadata.ogDescription}/>)}
    </Helmet>
  )
}

SanityHelmet.propTypes = {
  documentTags: PropTypes.object.isRequired,
  fallbackTags: PropTypes.object
};

export default SanityHelmet;